import React from 'react';

import styles from './SmallButton.module.scss';

const SmallButton = (
  props: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
) => {
  return <button {...props} className={styles.SmallButton} />;
};

export default SmallButton;
