import { combineReducers } from 'redux';

import { appReducer } from './app/reducer';
import { userReducer } from './user/reducer';

const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
});

export type ReduxStateType = ReturnType<typeof rootReducer>;

export default rootReducer;
