// prettier-ignore
import type {
  popupBoxColorType
} from 'src/components/common/PopupMessages/PopupMessages';
import type { GameNameType } from 'src/games/types';

export const SET_APP_VIEW = 'SET_APP_VIEW';
export const ADD_POPUP_MESSAGE = 'ADD_POPUP_MESSAGE';
export const SLICE_POPUP_MESSAGE = 'SLICE_POPUP_MESSAGE';
export const SET_WEBSOCKET_IS_OPEN = 'SET_WEBSOCKET_IS_OPEN';
export const SET_PLAYED_BATTLE_ID = 'SET_PLAYED_BATTLE_ID';
export const SET_PLAYED_GAMES = 'SET_PLAYED_GAMES';

export type appViewType = 'login' | 'home' | 'game';

export interface SetAppViewActionInterface {
  type: typeof SET_APP_VIEW;
  payload: {
    view: appViewType;
  };
}
export const setAppView = (view: appViewType): SetAppViewActionInterface => ({
  type: SET_APP_VIEW,
  payload: {
    view,
  },
});

export interface AddPopupMessageActionInterface {
  type: typeof ADD_POPUP_MESSAGE;
  payload: {
    message: string;
    color: popupBoxColorType;
  };
}
export const addPopupMessage = (
  message: string,
  color: popupBoxColorType = 'red',
): AddPopupMessageActionInterface => ({
  type: ADD_POPUP_MESSAGE,
  payload: {
    message,
    color,
  },
});

export interface SlicePopupMessageActionInterface {
  type: typeof SLICE_POPUP_MESSAGE;
}
export const slicePopupMessage = (): SlicePopupMessageActionInterface => ({
  type: SLICE_POPUP_MESSAGE,
});

export interface SetWebsocketIsOpenActionInterface {
  type: typeof SET_WEBSOCKET_IS_OPEN;
  payload: {
    isOpen: boolean;
  };
}
export const setWebsocketIsOpen = (
  isOpen: boolean,
): SetWebsocketIsOpenActionInterface => ({
  type: SET_WEBSOCKET_IS_OPEN,
  payload: {
    isOpen,
  },
});

export interface SetPlayedBattleIdActionInterface {
  type: 'SET_PLAYED_BATTLE_ID';
  payload: {
    battleId: string;
  };
}
export const setPlayedBattleId = (
  battleId: string,
): SetPlayedBattleIdActionInterface => ({
  type: SET_PLAYED_BATTLE_ID,
  payload: {
    battleId,
  },
});

export interface SetPlayedGamesActionInterface {
  type: 'SET_PLAYED_GAMES';
  payload: {
    battleId: string;
    games: GameNameType[];
  };
}
export const setPlayedGames = (
  battleId: string,
  games: GameNameType[],
): SetPlayedGamesActionInterface => ({
  type: SET_PLAYED_GAMES,
  payload: {
    battleId,
    games,
  },
});
