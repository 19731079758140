import React, { useState, useEffect } from 'react';

import socket from 'src/components/common/WebSocketConnection/websocket';

import './WaitingScreen.scss';

interface PlayerWaitingInterface {
  nickname: string;
  ready: boolean;
}

interface WaitingScreenPropsInterface {
  battleId: string;
  setWaitingScreen?: (state: boolean) => void;
}

const WaitingScreen = ({
  battleId,
  setWaitingScreen,
}: WaitingScreenPropsInterface) => {
  const [players, setPlayers] = useState([]);

  const waitingScreenSocketListener = (event: any) => {
    const action = JSON.parse(event.data);
    const { type, payload } = action;

    switch (type) {
      case 'UPDATE_PLAYERS_READY_FOR_GAME': {
        const { players } = payload;
        setPlayers(players);
        break;
      }

      case 'ALL_PLAYERS_READY': {
        if (payload.battleId === battleId)
          setWaitingScreen && setWaitingScreen(false);
        break;
      }

      default:
        break;
    }
  };

  useEffect(() => {
    socket.addEvent({
      name: 'WaitingScreen',
      type: 'message',
      handler: waitingScreenSocketListener,
    });
    socket.instance.send(
      JSON.stringify({
        type: 'SET_READY_FOR_GAME',
        payload: {
          battleId,
        },
      }),
    );
    return () => {
      socket.removeEvent({
        name: 'WaitingScreen',
        type: 'message',
        handler: waitingScreenSocketListener,
      });
    };
  }, []);

  return (
    <div className="WaitingScreen">
      <p>Waiting for players...</p>
      {players.map((player: PlayerWaitingInterface) => (
        <div key={player.nickname} className="player-row">
          <div>{player.nickname}</div>
          <div className={['status', player.ready && 'ready'].join(' ')}></div>
        </div>
      ))}
    </div>
  );
};

export default WaitingScreen;
