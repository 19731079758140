import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import type { ReduxStateType } from 'src/store/reducers';
import socket from 'src/components/common/WebSocketConnection/websocket';
import useSocketListener from 'src/hooks/useSocketListener';
import LoadingSquares from 'src/components/common/LoadingSquares';

import './LobbyList.scss';

interface LobbyType {
  joinCode: string;
  curPlayersCount: number;
  playersNeeded: number;
  games: {
    name: string;
    settings?: Record<string, unknown>;
  }[];
}

const LobbyList = () => {
  const [selectedLobby, setSelectedLobby] = useState('');
  const [lobbies, setLobbies] = useState<LobbyType[]>([]);
  const [waitingForLobbies, setWaitingForLobbies] = useState(false);

  const { isSocketOpen } = useSelector((state: ReduxStateType) => ({
    appView: state.app.appView,
    isSocketOpen: state.app.isSocketOpen,
    username: state.user.username,
  }));

  const lobbyListSocketListener = (event: any) => {
    const action = JSON.parse(event.data);
    const { type, payload } = action;

    switch (type) {
      case 'SEND_PUBLIC_LOBBIES': {
        setLobbies(payload.lobbies);
        setWaitingForLobbies(false);
        break;
      }

      default:
        break;
    }
  };

  const requestLobbies = () => {
    setWaitingForLobbies(true);
    socket.sendJSON({
      type: 'GET_PUBLIC_LOBBIES',
    });
  };

  useEffect(() => {
    if (isSocketOpen) {
      requestLobbies();
    }
  }, [isSocketOpen]);

  useSocketListener(
    'LobbyList',
    'message',
    useCallback(lobbyListSocketListener, []),
  );

  const joinLobby = () => {
    socket.sendJSON({
      type: 'JOIN_LOBBY',
      payload: {
        joinCode: selectedLobby,
      },
    });
  };

  return (
    <div className="LobbyList">
      {waitingForLobbies && <LoadingSquares />}
      <div className="actions">
        <button onClick={requestLobbies}>Refresh</button>
        <button disabled={!selectedLobby} onClick={joinLobby}>
          Join
        </button>
      </div>
      {lobbies.length > 0 ? (
        <table cellSpacing={0}>
          <thead>
            <tr>
              <th>Lobby ID</th>
              <th>Games</th>
              <th>Players</th>
            </tr>
          </thead>
          <tbody>
            {lobbies.map((lobby) => (
              <tr
                key={lobby.joinCode}
                className={[
                  lobby.joinCode === selectedLobby ? 'selected' : '',
                ].join(' ')}
                onClick={() =>
                  selectedLobby === lobby.joinCode
                    ? setSelectedLobby('')
                    : setSelectedLobby(lobby.joinCode)
                }
              >
                <td>{lobby.joinCode}</td>
                <td className="flexbox">
                  {lobby.games.map((game, i) => (
                    <div key={i} className="game">
                      {game.name}
                    </div>
                  ))}
                </td>
                <td>
                  {lobby.curPlayersCount}/{lobby.playersNeeded}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="no-lobbies-message">
          No public games available. Feel free to create one using the
          &quot;Create Lobby&quot; button.
        </div>
      )}
    </div>
  );
};

export default LobbyList;
