import React, { useState, useEffect, useCallback } from 'react';
import type { SyntheticEvent } from 'react';

import './Settings.scss';

import useSocketListener from 'src/hooks/useSocketListener';
import socket from 'src/components/common/WebSocketConnection/websocket';
import OptionBox from 'src/components/common/OptionBox';

const DEFAULTS = {
  nickname: 'Nick',
};

const Settings = () => {
  const [nickname, setNickname] = useState('');

  const settingsListener = (event: any) => {
    const action = JSON.parse(event.data);
    const { type, payload } = action;

    switch (type) {
      case 'SEND_SETTINGS': {
        const { nickname } = payload;
        setNickname(nickname);
        break;
      }

      default:
        break;
    }
  };

  useSocketListener('Settings', 'message', useCallback(settingsListener, []));

  useEffect(() => {
    socket.instance.send(
      JSON.stringify({
        type: 'GET_USER_SETTINGS',
      }),
    );
  }, []);

  const saveSettings = (e: SyntheticEvent) => {
    e.preventDefault();

    socket.instance.send(
      JSON.stringify({
        type: 'SAVE_SETTINGS',
        payload: {
          nickname,
        },
      }),
    );
  };

  return (
    <div className="Settings">
      <form onSubmit={saveSettings}>
        <div className="setting">
          <OptionBox
            name="Nickname"
            type="text"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            placeholder={DEFAULTS.nickname}
            required
            minLength={4}
            maxLength={14}
            pattern="[a-zA-Z0-9]*"
          />
          <div className="option-note">
            Nicknames can be changed only once!
            <br />
            Only simple letters and numbers are allowed.
          </div>
        </div>
        <button>Save</button>
      </form>
      <div className="credits">
        Music by{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://pixabay.com/users/xtremefreddy-32332307/"
        >
          XtremeFreddy
        </a>{' '}
        from{' '}
        <a target="_blank" rel="noreferrer" href="https://pixabay.com/">
          Pixabay
        </a>
      </div>
    </div>
  );
};

export default Settings;
