import React from 'react';

import './BlackOverlay.scss';

interface BlackOverlayPropsInterface {
  children: React.ReactNode;
}

// TODO: Add option to add default modal?

const BlackOverlay = ({ children }: BlackOverlayPropsInterface) => {
  return <div className="BlackOverlay">{children}</div>;
};

export default BlackOverlay;
