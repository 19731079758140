import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import './RejoinGame.scss';

import socket from 'src/components/common/WebSocketConnection/websocket';
import LoadingSquares from 'src/components/common/LoadingSquares';
import { setPlayedGames } from 'src/store/app/actions';
import { setAppView } from 'src/store/app/actions';
import type { GameNameType } from 'src/games/types';

interface BattleToRejoinInterface {
  id: string;
  games: GameNameType[];
  currentGameName: string;
}

const RejoinGame = () => {
  const [battlesToRejoin, setBattlesToRejoin] = useState<
    BattleToRejoinInterface[] | null
  >(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const rejoinGame = (battle: BattleToRejoinInterface) => {
    if (!battlesToRejoin) return false;

    socket.instance.send(
      JSON.stringify({
        type: 'REJOIN_GAME',
        payload: {
          battleId: battle.id,
        },
      }),
    );
    dispatch(setPlayedGames(battle.id, battle.games));
    dispatch(setAppView('game'));
  };

  const rejoinGameSocketListener = (event: any) => {
    const action = JSON.parse(event.data);
    const { type, payload } = action;

    switch (type) {
      case 'GAMES_TO_REJOIN_LIST': {
        const { battles } = payload;
        setBattlesToRejoin(battles);
        setLoading(false);
        break;
      }

      default:
        break;
    }
  };

  useEffect(() => {
    socket.instance.send(
      JSON.stringify({
        type: 'REQUEST_GAMES_TO_REJOIN',
      }),
    );
    setLoading(true);

    socket.addEvent({
      name: 'RejoinGame',
      type: 'message',
      handler: rejoinGameSocketListener,
    });
    return () => {
      socket.removeEvent({
        name: 'RejoinGame',
        type: 'message',
        handler: rejoinGameSocketListener,
      });
    };
  }, []);

  return (
    <div className="RejoinGame">
      {loading && <LoadingSquares />}
      {battlesToRejoin && battlesToRejoin.length > 0 ? (
        battlesToRejoin.map((battle) => (
          <div key={battle.id} className="game-row">
            <div className="game-id">{battle.id}</div>
            <div className="rightside">
              <div className="game-name" title="Current game name">
                {battle.currentGameName}
              </div>
              <button
                className="rejoin-button"
                onClick={() => rejoinGame(battle)}
              >
                Rejoin
              </button>
            </div>
          </div>
        ))
      ) : (
        <div>No games to rejoin</div>
      )}
    </div>
  );
};

export default RejoinGame;
