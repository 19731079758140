import React, { useEffect, useState } from 'react';

import './VolumeAdjuster.scss';

import {
  pauseMusicLoop,
  playSound,
  setVolume,
  startMusicLoop,
} from 'src/modules/soundHandler';

interface VolumeAdjusterPropsInterface {
  soundType: 'effect' | 'music';
}
const VolumeAdjuster = ({ soundType }: VolumeAdjusterPropsInterface) => {
  const [soundEnabled, setSoundEnabled] = useState(true);
  const [soundVolume, setSoundVolume] = useState(30);
  // TODO: Save volume in local storage.

  const toggleVolume = () => {
    setSoundEnabled((prevState) => {
      setVolume(soundType, prevState ? 0 : soundVolume / 100);
      playSound('tilesSubmitted');

      if (prevState) {
        localStorage.setItem(`${soundType}-volume`, '0');
      } else {
        localStorage.setItem(`${soundType}-volume`, `${soundVolume / 100}`);
      }

      return !prevState;
    });
  };

  const submitChangeVolume = (event: any) => {
    setVolume(soundType, event.target.value / 100);
    playSound('tilesSubmitted');

    localStorage.setItem(`${soundType}-volume`, `${event.target.value / 100}`);
  };

  useEffect(() => {
    const volumeString = localStorage.getItem(`${soundType}-volume`);
    const volume = volumeString !== null ? Number(volumeString) : soundVolume;

    if (soundType === 'music') {
      startMusicLoop(volume !== 0);
    }

    if (volumeString !== null) {
      if (volume === 0) {
        setSoundEnabled(false);
      } else {
        setSoundVolume(volume * 100);
      }
      setVolume(soundType, volume, false);
    }

    if (soundType === 'music') {
      return () => {
        pauseMusicLoop();
      };
    }
  }, []);

  return (
    <div className="VolumeAdjuster">
      <div className="sound-icon" onClick={toggleVolume}>
        {soundType === 'effect' && (soundEnabled ? '🔉' : '🔈')}
        {soundType === 'music' && (soundEnabled ? '🎵' : '🔇')}
      </div>
      <input
        type="range"
        min="0"
        max="100"
        value={soundVolume}
        onInput={(event: any) => setSoundVolume(event.target.value)}
        onMouseUp={submitChangeVolume}
        onTouchEnd={submitChangeVolume}
        disabled={!soundEnabled}
      />
    </div>
  );
};

export default VolumeAdjuster;
