import React, { useEffect, useRef, useState } from 'react';
import Marquee from 'react-fast-marquee';

import { GAMES_LIST } from 'src/games/types';
import styles from './About.module.scss';

const FEATURES_LIST = [
  'Multiplayer',
  'Desktop & Mobile support',
  'Fast phased',
  'With friends or randoms',
  'Ranking system',
  'FUN!',
];

const About = () => {
  const [featureIteration, setFeatureIteration] = useState(0);

  const featureFlipBoardRef = useRef<HTMLDivElement | null>(null);
  const featureFilpBoardElementsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const flipBoardHeight = featureFlipBoardRef.current?.clientHeight;

    if (flipBoardHeight) {
      const timeout = setTimeout(() => {
        if (FEATURES_LIST[featureIteration + 1]) {
          setFeatureIteration(featureIteration + 1);
          if (featureFlipBoardRef?.current?.scrollHeight) {
            featureFlipBoardRef.current.scrollTop =
              flipBoardHeight * featureIteration;
          }
        } else {
          setFeatureIteration(0);
        }
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [featureIteration, featureFlipBoardRef.current]);

  useEffect(() => {
    if (featureFlipBoardRef.current && featureFilpBoardElementsRef.current) {
      // eslint-disable-next-line max-len
      featureFlipBoardRef.current.style.height = `${featureFilpBoardElementsRef.current?.clientHeight}px`;
    }
  }, [featureFlipBoardRef.current, featureFilpBoardElementsRef.current]);

  return (
    <div className={styles.About}>
      <div>
        <h3>doteric&apos;s</h3>
        <h2>Games Lobby</h2>
      </div>
      <div ref={featureFlipBoardRef} className={styles['flip-board']}>
        {FEATURES_LIST.map((feature) => (
          <div key={feature} ref={featureFilpBoardElementsRef}>
            {feature}
          </div>
        ))}
      </div>
      <div className={styles['games-list']}>
        <Marquee pauseOnHover>
          {GAMES_LIST.map((gameName) => (
            <div key={gameName} className={styles['game-box']}>
              {gameName}
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default About;
