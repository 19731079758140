const shuffleArray = <T>(array: T[]) => {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = <[T, T]>[newArray[j], newArray[i]];
  }
  return newArray;
};

export default shuffleArray;
