import React, { useState } from 'react';

import './GameSquare.scss';

import OptionBox from 'src/components/common/OptionBox';
import type { GameNameType } from 'src/games/types';
import { SETTINGS_FOR_GAMES, GAMES_DESCRIPTION } from '../settingsForGames';

import rummikub from './images/rummikub.jpg';
import abcd from './images/abcd.png';
import tictactoe from './images/tictactoe.jpg';
// TODO: Add retrosnake image
import spotmatch from './images/spotmatch.jpg';
import speedmaze from './images/speedmaze.png';

const images: Record<string, string> = {
  rummikub,
  abcd,
  tictactoe,
  spotmatch,
  speedmaze,
};

export interface GameType {
  name: GameNameType;
  settings: Record<string, string>;
}

interface GameSquareBasePropsType {
  game: GameType;
  size?: 'medium' | 'large';
}

interface GameSquareSelectedPropsType extends GameSquareBasePropsType {
  type: 'selected';
  gameNr: number;
  removeGame: (gameNr: number) => void;
  updateGameSetting: (
    gameNr: number,
    settingName: string,
    value: string,
  ) => void;
  moveAction: (gameNr: number, where: 'left' | 'right') => void;
  disabledMoveLeft: boolean;
  disabledMoveRight: boolean;
}

interface GameSquareInListPropsType extends GameSquareBasePropsType {
  type: 'in-list';
  addGame: (name: GameNameType) => void;
}

interface GameSquareShowcasePropsType extends GameSquareBasePropsType {
  type: 'showcase';
}

interface GameSquareCheckListPropsType extends GameSquareBasePropsType {
  type: 'check-list';
  toggleGame: () => void;
  gameEnabled: boolean;
}

type GameSquarePropsType =
  | GameSquareSelectedPropsType
  | GameSquareInListPropsType
  | GameSquareShowcasePropsType
  | GameSquareCheckListPropsType;

/*
  TODO: Convert this component to a modular one
        that just accepts true/false whether to show a certain part
        or for example provide array of button contents and colors.
*/

const GameSquare = (props: GameSquarePropsType) => {
  const { type, game, size = 'medium' } = props;

  const [settingsOpened, setSettingsOpened] = useState(false);

  const playersNeededSetting = SETTINGS_FOR_GAMES[game.name].find(
    (setting) => setting.name === 'playersNeeded',
  );

  const settings = SETTINGS_FOR_GAMES[game.name].filter(
    (setting) => setting.name !== 'playersNeeded',
  );

  if (type === 'in-list' || type === 'check-list') {
    return (
      <div className={['GameSquare', size].join(' ')}>
        {images[game.name] && (
          <div className="background">
            <img src={images[game.name]} loading="lazy" />
          </div>
        )}
        <div className="main-content">
          <div className="players-range">
            ⛹️ {playersNeededSetting?.min}-{playersNeededSetting?.max}
          </div>
          <div className="name">{game.name}</div>
          <div className="context">{GAMES_DESCRIPTION[game.name]}</div>
        </div>
        <div className="actions">
          {type === 'in-list' && (
            <button className="add" onClick={() => props.addGame(game.name)}>
              Add
            </button>
          )}
          {type === 'check-list' && (
            <button
              className={[
                'single-button',
                props.gameEnabled ? 'remove' : 'add',
              ].join(' ')}
              onClick={() => {
                props.toggleGame();
              }}
            >
              {props.gameEnabled ? 'Remove' : 'Add'}
            </button>
          )}
        </div>
      </div>
    );
  }

  if (type === 'selected') {
    const {
      gameNr,
      removeGame,
      updateGameSetting,
      moveAction,
      disabledMoveLeft,
      disabledMoveRight,
    } = props;
    return (
      <div className="GameSquare">
        <div className="actions">
          {!disabledMoveLeft && (
            <button
              className="move-left"
              onClick={() => moveAction(gameNr, 'left')}
            >
              {'<'}
            </button>
          )}
          {!disabledMoveRight && (
            <button
              className="move-right"
              onClick={() => moveAction(gameNr, 'right')}
            >
              {'>'}
            </button>
          )}
        </div>
        {images[game.name] && (
          <div className="background">
            <img src={images[game.name]} loading="lazy" />
          </div>
        )}
        <div className="main-content">
          <div>{game.name}</div>
          <div className="context">
            {settings.map((setting) => (
              <div key={setting.name}>
                {setting.display}:{' '}
                {game.settings[setting.name] || setting.default}
              </div>
            ))}
          </div>
        </div>
        <div className="actions">
          <button className="settings" onClick={() => setSettingsOpened(true)}>
            Settings
          </button>
          <button className="remove" onClick={() => removeGame(gameNr)}>
            Remove
          </button>
        </div>
        {settingsOpened && (
          /* TODO: Consider changing to black overlay component? */
          <div className="settings-holder">
            <div className="box">
              <form onSubmit={() => setSettingsOpened(false)}>
                {settings.map((setting) => (
                  <OptionBox
                    key={setting.name}
                    type={setting.type}
                    name={setting.display}
                    placeholder={`${setting.default}`}
                    value={(game.settings && game.settings[setting.name]) || ''}
                    onChange={(e) =>
                      updateGameSetting(gameNr, setting.name, e.target.value)
                    }
                    min={setting.min}
                    max={setting.max}
                  />
                ))}
                <button onClick={() => setSettingsOpened(false)}>
                  Save & close
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }

  if (type === 'showcase') {
    return (
      <div className="GameSquare">
        {images[game.name] && (
          <div className="background">
            <img src={images[game.name]} loading="lazy" />
          </div>
        )}
        <div className="main-content">
          <div>{game.name}</div>
          <div className="context">
            {settings.map((setting) => (
              <div key={setting.name}>
                {setting.display}:{' '}
                {game.settings[setting.name] || setting.default}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default GameSquare;
