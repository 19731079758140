import React, { useState } from 'react';

import './Home.scss';

import SquareButton from 'src/components/common/SquareButton';
import BackButton from 'src/components/homeView/BackButton';
import VersionsInfo from 'src/components/homeView/VersionsInfo';
import LobbyList from 'src/components/homeView/LobbyList';
import LobbyController from 'src/components/homeView/LobbyController';
import ThinSeparator from 'src/components/common/ThinSeparator';
import JoinLobby from 'src/components/homeView/JoinLobby';

import Matchmaking from './Matchmaking';
import CreateLobby from './CreateLobby';
import RejoinGame from './RejoinGame';
import Leaderboard from './Leaderboard';
import Settings from './Settings';

type subViewType =
  | 'home'
  | 'create-lobby'
  | 'rejoin-game'
  | 'matchmaking'
  | 'leaderboard'
  | 'settings';

const Home = () => {
  const [subView, setSubView] = useState<subViewType>('home');

  const SUB_VIEW_COMPONENTS = {
    home: (
      <>
        <JoinLobby />
        <ThinSeparator angle="horizontal">or</ThinSeparator>
        <LobbyList />
        <div className="home-menu">
          <SquareButton
            text="Quick Play (Matchmaking)"
            colorPreset="green"
            noHeight={true}
            onClick={() => setSubView('matchmaking')}
          />
          <SquareButton
            text="Create Lobby"
            colorPreset="blue"
            noHeight={true}
            onClick={() => setSubView('create-lobby')}
          />
          <SquareButton
            text="Rejoin Game"
            colorPreset="red"
            noHeight={true}
            onClick={() => setSubView('rejoin-game')}
          />
          <SquareButton
            text="Leaderboard (WIP)"
            colorPreset="yellow"
            noHeight={true}
            onClick={() => setSubView('leaderboard')}
          />
          <SquareButton
            text="Settings"
            colorPreset="lightblue"
            noHeight={true}
            onClick={() => setSubView('settings')}
          />
        </div>
      </>
    ),
    matchmaking: <Matchmaking />,
    'create-lobby': <CreateLobby />,
    'rejoin-game': <RejoinGame />,
    leaderboard: <Leaderboard />,
    settings: <Settings />,
  };

  const subViewComponent = SUB_VIEW_COMPONENTS[subView];

  return (
    <div className="Home">
      <LobbyController>
        {subView !== 'home' && (
          <div className="backbutton-holder">
            <BackButton onClick={() => setSubView('home')} />
          </div>
        )}
        {subViewComponent}
        <VersionsInfo />
      </LobbyController>
    </div>
  );
};

export default Home;
