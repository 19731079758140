import {
  SET_APP_VIEW,
  ADD_POPUP_MESSAGE,
  SLICE_POPUP_MESSAGE,
  SET_WEBSOCKET_IS_OPEN,
  SET_PLAYED_BATTLE_ID,
  SET_PLAYED_GAMES,
} from './actions';

import type {
  appViewType,
  SetAppViewActionInterface,
  AddPopupMessageActionInterface,
  SlicePopupMessageActionInterface,
  SetWebsocketIsOpenActionInterface,
  SetPlayedBattleIdActionInterface,
  SetPlayedGamesActionInterface,
} from './actions';
// prettier-ignore
import type {
  popupBoxColorType
} from 'src/components/common/PopupMessages/PopupMessages';
import type { GameNameType } from 'src/games/types';

export interface AppReducerInterface {
  appView: appViewType;
  isSocketOpen: boolean;
  popupMessages: { message: string; color: popupBoxColorType }[];
  // TODO: separate reducer for global games state
  battleId: string | null;
  games: GameNameType[];
  curGame: GameNameType | null;
}

// TODO: Add websocket connection state
// so I can hook up all the listeners based on that
export const initialState: AppReducerInterface = {
  appView: 'login',
  isSocketOpen: false,
  popupMessages: [],
  battleId: null,
  games: [],
  curGame: null,
};

type appReducerActionType =
  | SetAppViewActionInterface
  | AddPopupMessageActionInterface
  | SlicePopupMessageActionInterface
  | SetWebsocketIsOpenActionInterface
  | SetPlayedBattleIdActionInterface
  | SetPlayedGamesActionInterface;

/* eslint-disable no-case-declarations */
export const appReducer = (
  state: AppReducerInterface = initialState,
  action: appReducerActionType,
): AppReducerInterface => {
  switch (action.type) {
    case SET_APP_VIEW:
      const { view } = action.payload;
      return {
        ...state,
        appView: view,
      };

    case ADD_POPUP_MESSAGE:
      const { message, color } = action.payload;
      return {
        ...state,
        popupMessages: [...state.popupMessages, { message, color }],
      };

    case SLICE_POPUP_MESSAGE:
      return {
        ...state,
        popupMessages: [...state.popupMessages.slice(1)],
      };

    case SET_WEBSOCKET_IS_OPEN:
      const { isOpen } = action.payload;
      return {
        ...state,
        isSocketOpen: isOpen,
      };

    case SET_PLAYED_BATTLE_ID: {
      const { battleId } = action.payload;
      return {
        ...state,
        battleId,
      };
    }

    case SET_PLAYED_GAMES: {
      const { battleId, games } = action.payload;
      return {
        ...state,
        battleId,
        games,
      };
    }

    default:
      return state;
  }
};
