import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import socket from 'src/components/common/WebSocketConnection/websocket';
import { showPopup } from 'src/utils/popups';

import './PopupMessages.scss';

export type popupBoxColorType = 'red' | 'yellow' | 'blue';

interface PopupMessageBoxPropsInterface {
  message: string;
  color: popupBoxColorType;
}

const PopupMessageBox = ({ message, color }: PopupMessageBoxPropsInterface) => {
  const [hidden, setHidden] = useState(false);

  return hidden ? null : (
    <div
      className={['PopupMessageBox', color].join(' ')}
      onClick={() => setHidden(true)}
    >
      {message}
    </div>
  );
};

const PopupMessages = () => {
  const popups: PopupMessageBoxPropsInterface[] = useSelector(
    (state: any) => state.app.popupMessages,
  );
  const dispatch = useDispatch();

  const popupMessagesSocketListener = (event: any) => {
    const action = JSON.parse(event.data);
    const { type, payload } = action;

    switch (type) {
      case 'POPUP_MESSAGE': {
        const { message, color = 'red' } = payload;
        showPopup(dispatch, { message, color });
        break;
      }

      default:
        break;
    }
  };

  useEffect(() => {
    socket.addEvent({
      name: 'PopupMessages',
      type: 'message',
      handler: popupMessagesSocketListener,
    });
    return () => {
      socket.removeEvent({
        name: 'PopupMessages',
        type: 'message',
        handler: popupMessagesSocketListener,
      });
    };
  }, []);

  return (
    <div className="PopupMessages">
      {popups.map((popup, index) => (
        <PopupMessageBox
          key={index}
          message={popup.message}
          color={popup.color}
        />
      ))}
    </div>
  );
};

export default PopupMessages;
