import { SET_USER_NAME } from './actions';

import type { SetUserActionInterface } from './actions';

export interface UserReducerInterface {
  username: string;
}

export const initialState: UserReducerInterface = {
  username: '',
};

type appReducerActionType = SetUserActionInterface;

/* eslint-disable no-case-declarations */
export const userReducer = (
  state: UserReducerInterface = initialState,
  action: appReducerActionType
): UserReducerInterface => {
  switch (action.type) {
    case SET_USER_NAME:
      const { username } = action.payload;
      return {
        ...state,
        username,
      };

    default:
      return state;
  }
};
