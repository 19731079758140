import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import useSocketListener from 'src/hooks/useSocketListener';
import { ReduxStateType } from 'src/store/reducers';
import GamesPreloader from 'src/components/gamesCoordinator/GamesPreloader';
import LoadingSquares from 'src/components/common/LoadingSquares';
import lazyWithPreload from 'src/utils/reactLazyWithPreload';
// eslint-disable-next-line max-len
import BattleFinishedOverlay from 'src/components/gamesCoordinator/BattleFinishedOverlay';
import type { PreloadableComponent } from 'src/utils/reactLazyWithPreload';
import Leaderboard from 'src/components/gamesCoordinator/Leaderboard';
import type { GameNameType } from 'src/games/types';
import WaitingScreen from 'src/games/rummikub/components/WaitingScreen';
import PlayersSidePanel from 'src/components/gamesCoordinator/PlayersSidePanel';
// eslint-disable-next-line max-len
import type { LeaderboardUserType } from 'src/components/gamesCoordinator/Leaderboard/Leaderboard';
import { setMusicSpeed } from 'src/modules/soundHandler';
import checkIsSmallWidth from 'src/utils/checkIsSmallWidth';
import styles from './GamesCoordinator.module.scss';

// Games
const Rummikub = lazyWithPreload(() => import('src/games/rummikub'));
const Abcd = lazyWithPreload(() => import('src/games/abcd'));
const TicTacToe = lazyWithPreload(() => import('src/games/ticTacToe'));
const RetroSnake = lazyWithPreload(() => import('src/games/retroSnake'));
const SpotMatch = lazyWithPreload(() => import('src/games/spotMatch'));
const SpeedMaze = lazyWithPreload(() => import('src/games/speedMaze'));
const SpeedPoly = lazyWithPreload(() => import('src/games/speedpoly'));

export const gameToComponentMap: Record<
  GameNameType,
  PreloadableComponent<() => React.ReactElement>
> = {
  rummikub: Rummikub,
  abcd: Abcd,
  tictactoe: TicTacToe,
  retrosnake: RetroSnake,
  spotmatch: SpotMatch,
  speedmaze: SpeedMaze,
  speedpoly: SpeedPoly,
};

type GamesCoordinatorViewsType = 'waiting-screen' | 'game' | 'leaderboard';

const GamesCoordinator = () => {
  const { battleId, games } = useSelector((state: ReduxStateType) => ({
    battleId: state.app.battleId,
    games: state.app.games,
  }));
  const [curGame, setCurGame] = useState<GameNameType>(games[0]);
  const [gamesPreloaded, setGamesPreloaded] = useState(false);
  const [gameResultsHistory, setGameResultsHistory] = useState<
    { name: string; results: LeaderboardUserType[] }[]
  >([]);
  const [usersLeaderboard, setUsersLeaderboard] = useState<
    LeaderboardUserType[]
  >([]);
  const [playerElos, setPlayerElos] = useState<LeaderboardUserType[]>([]);
  const [curView, setCurView] =
    useState<GamesCoordinatorViewsType>('waiting-screen');
  const [isSmallWidth, setIsSmallWidth] = useState(checkIsSmallWidth());
  const gamesResultsEndDummyRef = useRef<HTMLElement>(null);

  const gamesCoordinatorSocketListener = (event: any) => {
    const action = JSON.parse(event.data);
    const { type, payload } = action;

    switch (type) {
      // TODO: Instead of ALL_PLAYERS_READY use only BATTLE_STARTED
      // Currently BATTLE_STARTED is used only for rejoining
      case 'ALL_PLAYERS_READY': {
        setCurGame(games[0]);
        setCurView('game');
        break;
      }

      case 'BATTLE_STARTED': {
        const { curGame } = payload;
        setCurGame(curGame);
        setCurView('game');
        break;
      }

      case 'BATTLE_LEADERBOARD': {
        setCurView('leaderboard');
        if (usersLeaderboard.length > 0) {
          setTimeout(() => {
            setUsersLeaderboard(payload.leaderboard);
          }, 1000);
        } else {
          setUsersLeaderboard(payload.leaderboard);
        }
        break;
      }

      case 'NEXT_GAME': {
        const { gameNr } = payload;
        /*
          TODO: We might get a situation where the previous game is the
          same as the next game and the component won't clean itself.
          Either I need to re-create the component here or on each game start
          do some cleanup.
        */
        setCurGame(games[gameNr]);
        break;
      }

      case 'NEXT_GAME_START': {
        setCurView('game');
        break;
      }

      case 'SEND_GAME_RESULTS': {
        const { gameResults, gameName } = payload;
        setGameResultsHistory((prev) => [
          ...prev,
          { name: gameName, results: gameResults },
        ]);
        gamesResultsEndDummyRef.current?.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
        break;
      }

      case 'SEND_UPDATED_PLAYER_ELOS': {
        const { users } = payload;
        setPlayerElos(
          users.map((user: any) => ({
            nickname: user.nickname,
            color: user.color,
            points: user.oldElo,
            addedPoints: user.newElo - user.oldElo,
          }))
        );
        gamesResultsEndDummyRef.current?.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
        break;
      }

      default:
        break;
    }
  };

  useSocketListener(
    'GamesCoordinator',
    'message',
    useCallback(gamesCoordinatorSocketListener, [usersLeaderboard])
  );

  useEffect(() => {
    // TODO: Request battle status update
    // & Send info that player in coordinator?

    setMusicSpeed(1.25);

    return () => {
      // TODO: send that player left coordinator for some reason?

      setMusicSpeed(1);
    };
  }, []);

  useEffect(() => {
    const onWindowResize = () => {
      setIsSmallWidth(checkIsSmallWidth());
    };

    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  const confirmWindowClose = (e: Event) => {
    // Cancel the event
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = true;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', confirmWindowClose);
    return () => {
      window.removeEventListener('beforeunload', confirmWindowClose);
    };
  }, []);

  if (!battleId) {
    return <div>Error: Battle ID not provided</div>;
  }

  if (!gamesPreloaded) {
    return (
      <GamesPreloader
        components={games.map((name) => gameToComponentMap[name])}
        gamesLoadedCallback={() => setGamesPreloaded(true)}
      />
    );
  }

  const GameComponent = gameToComponentMap[curGame];
  const ViewComponents = {
    'waiting-screen': <WaitingScreen battleId={battleId} />,
    game: (
      <>
        <PlayersSidePanel mobileView={isSmallWidth} />
        <GameComponent />
      </>
    ),
    leaderboard: (
      <>
        <div className={styles.flexList}>
          {gameResultsHistory.map((game, index) => (
            <Leaderboard
              key={`${game.name}-${index}`}
              title={`Game: ${game.name}`}
              users={game.results}
            />
          ))}
          <Leaderboard title="Battle" users={usersLeaderboard} />
          {playerElos.length > 0 && (
            <Leaderboard title="Elo" users={playerElos} />
          )}
          <span ref={gamesResultsEndDummyRef} className="dummy"></span>
          {/* TODO: Show upcoming games */}
        </div>
        <BattleFinishedOverlay />
      </>
    ),
  } as const;

  return (
    <Suspense fallback={<LoadingSquares />}>
      {(Object.keys(ViewComponents) as (keyof typeof ViewComponents)[]).map(
        (key) => (
          <div
            key={key}
            className={[
              styles.GamesCoordinator,
              styles[key],
              curView !== key && styles.hidden,
              isSmallWidth && styles.mobile_view,
            ]
              .filter(Boolean)
              .join(' ')}
          >
            {ViewComponents[key]}
          </div>
        )
      )}
    </Suspense>
  );
};

export default GamesCoordinator;
