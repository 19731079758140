import store from 'src/store';
import { setWebsocketIsOpen } from 'src/store/app/actions';
import { isDebugMode } from 'src/modules/debugMode';

const socketUrl =
  process.env.NODE_ENV === 'development'
    ? 'ws://localhost:8080'
    : `wss://${window.location.hostname}/backend`;

export const WS_READY_STATES = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

interface WsEventInterface {
  name: string;
  type: 'message' | 'close' | 'error';
  handler: (event: any) => void;
}

class WebSocketConnection {
  private socket?: WebSocket;
  private events: WsEventInterface[] = [];

  initConnection() {
    if (isDebugMode()) console.log('Starting WebSocket connection...');
    this.socket = new WebSocket(socketUrl);
    // TODO: The below can happen before the socket is established
    this.addListeners();
  }

  private addListeners() {
    if (this.socket) {
      // Connection opened
      this.socket.addEventListener('open', (event: any) => {
        if (isDebugMode()) console.log('open event', event);
        store.dispatch(setWebsocketIsOpen(true));
        this.socket?.send('Hello Server!');
      });

      this.socket.addEventListener('close', (event: any) => {
        if (isDebugMode()) console.log('close event', event);
        store.dispatch(setWebsocketIsOpen(false));
      });

      // Add gathered events
      this.events.forEach((event) => {
        const { type, handler } = event;
        this.socket?.addEventListener(type, handler);
      });
    }
  }

  get instance() {
    return this.socket as WebSocket;
  }

  reconnect() {
    if (this.socket?.readyState !== WS_READY_STATES.OPEN) {
      delete this.socket;
      this.initConnection();
    }

    return this.socket;
  }

  addEvent(event: WsEventInterface) {
    this.events.push(event);
    if (this.socket) {
      const { type, handler } = event;
      this.socket.addEventListener(type, handler);
    }
  }

  removeEvent(event: WsEventInterface) {
    const { name, type, handler } = event;
    // TODO: Consider event duplicate names. Prevention or handling.
    this.events = this.events.filter((event) => event.name !== name);
    if (this.socket) {
      this.socket.removeEventListener(type, handler);
    }
  }

  async waitForSocketStatus() {
    if (this.socket?.readyState === WS_READY_STATES.CONNECTING) {
      return await new Promise((resolve) => {
        setTimeout(() => {
          resolve(this.waitForSocketStatus());
        }, 300);
      });
    }

    return this.socket?.readyState;
  }

  closeConnection() {
    console.log('Closing WebSocket connection...');
    socket.instance.close();
  }

  sendJSON(data: unknown) {
    this.socket?.send(JSON.stringify(data));
  }
}

const socket = new WebSocketConnection();

export default socket;
