import React, { useEffect, useRef, useState } from 'react';
import type { FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import type { User } from 'firebase/auth';

import SquareButton from 'src/components/common/SquareButton';
import ThinSeparator from 'src/components/common/ThinSeparator';
import { setAppView } from 'src/store/app/actions';
import { setUserName } from 'src/store/user/actions';
import LoadingSquares from 'src/components/common/LoadingSquares';
import { fetchServer } from 'src/utils/serverRequests';
import {
  waitForFirebaseInit,
  registerEmail,
  loginEmail,
  verifyFirebaseToken,
  getFirebaseUserToken,
} from 'src/modules/firebase/utils';
import ConfirmEmail from 'src/components/login/ConfirmEmail';
import About from 'src/components/login/About';
import ResetPasswordModal from './ResetPasswordModal';
import checkIsSmallWidth from 'src/utils/checkIsSmallWidth';

import './Login.scss';

const Login = (): React.ReactElement => {
  const [fetching, setFetching] = useState(false);
  const [isSmallWidth, setIsSmallWidth] = useState(checkIsSmallWidth());
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showConfirmEmail, setShowConfirmEmail] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  const mounted = useRef(false);

  const dispatch = useDispatch();

  const setFetchingFalse = () => {
    if (mounted.current) {
      setFetching(false);
    }
  };

  const loginSuccess = (username: string) => {
    dispatch(setUserName(username));
    dispatch(setAppView('home'));
  };

  const checkTokenAndLogin = async (token: string | undefined) => {
    if (token) {
      const verified = await verifyFirebaseToken(token);
      if (verified) {
        if (verified.emailVerified) {
          loginSuccess(verified.username);
        } else {
          setShowConfirmEmail(true);
        }
      }
    }
  };

  const attemptLogin = async (event: FormEvent) => {
    event.preventDefault();
    setFetching(true);
    const nativeEvent = event.nativeEvent as SubmitEvent;
    const submitter = nativeEvent.submitter as { name?: string };
    if (submitter.name === 'login') {
      const token = await loginEmail(email, password);
      await checkTokenAndLogin(token);
    } else if (submitter.name === 'register') {
      const token = await registerEmail(email, password);
      if (token) {
        setShowConfirmEmail(true);
      }
    } else {
      console.warn('Unhandled button name');
    }
    setFetchingFalse();
  };

  const loginAsGuest = async () => {
    setFetching(true);
    const data = await fetchServer({
      path: '/loginGuest',
      body: {},
    });
    setFetching(false);
    if (data?.result === 'OK') {
      loginSuccess(data.username);
    } else {
      console.log('Failed login as guest?');
    }
  };

  const checkLogin = async (user: User | null) => {
    setFetching(true);
    if (user) {
      if (user.email) setEmail(user.email);
      const token = await user.getIdToken();
      await checkTokenAndLogin(token);
    } else {
      // Check guest login
      const data = await fetchServer({
        path: '/checkLoginGuest',
        body: {},
      });
      if (data?.result === 'OK') {
        loginSuccess(data.username);
      }
    }
    setFetchingFalse();
  };

  const onConfirmEmailClosed = async () => {
    setFetching(true);
    setShowConfirmEmail(false);
    const token = await getFirebaseUserToken();
    await checkTokenAndLogin(token);
    // Only set fetching to false if allowed
    setFetchingFalse();
  };

  useEffect(() => {
    setFetching(true);
    waitForFirebaseInit((user) => {
      setFetchingFalse();
      checkLogin(user);
    });
  }, []);

  useEffect(() => {
    mounted.current = true;

    const onWindowResize = () => {
      setIsSmallWidth(checkIsSmallWidth());
    };

    window.addEventListener('resize', onWindowResize);
    return () => {
      mounted.current = false;
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  return (
    <div className="login-holder">
      <About />
      <div
        className={['Login', isSmallWidth && 'smallWidth']
          .filter(Boolean)
          .join(' ')}
      >
        <div className="user-login">
          <form onSubmit={attemptLogin}>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <div className="submit-buttons">
              <button name="login">Login</button>
              <button name="register">Register</button>
            </div>
          </form>
          <div className="forgot-password-holder">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowResetPasswordModal(true);
              }}
            >
              Forgot password
            </a>
          </div>
        </div>
        <ThinSeparator angle={isSmallWidth ? 'horizontal' : 'vertical'}>
          or
        </ThinSeparator>
        <div className="guest-login">
          <SquareButton
            text="Login as Guest"
            colorPreset="yellow"
            onClick={loginAsGuest}
            disabled={fetching}
          />
        </div>
        {showConfirmEmail && (
          <ConfirmEmail
            email={email}
            onCloseConfirmEmail={onConfirmEmailClosed}
          />
        )}
        {showResetPasswordModal && (
          <ResetPasswordModal
            setFetching={setFetching}
            onCloseModal={() => setShowResetPasswordModal(false)}
          />
        )}
        {fetching && <LoadingSquares />}
      </div>
    </div>
  );
};

export default Login;
