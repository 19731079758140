import { playSound } from 'src/modules/soundHandler';
import { addPopupMessage, slicePopupMessage } from 'src/store/app/actions';

// prettier-ignore
import type {
  popupBoxColorType
} from 'src/components/common/PopupMessages/PopupMessages';
import type { Dispatch } from 'redux';

interface PopupMessage {
  message: string;
  color?: popupBoxColorType;
}

export const showPopup = (dispatch: Dispatch, message: PopupMessage) => {
  dispatch(addPopupMessage(message.message, message.color));

  if (message.color === 'blue') {
    playSound('successPopup');
  } else {
    playSound('errorPopup');
  }

  setTimeout(() => {
    dispatch(slicePopupMessage());
  }, 5 * 1000);
};
