import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setAppView } from 'src/store/app/actions';
import { setUserName } from 'src/store/user/actions';
import { backendUrl, fetchServer } from 'src/utils/serverRequests';
import VolumeAdjuster from 'src/components/common/VolumeAdjuster';
import { playSound } from 'src/modules/soundHandler';
import { signOut } from 'src/modules/firebase/utils';
import socket, {
  WS_READY_STATES,
} from 'src/components/common/WebSocketConnection/websocket';
import type { ReduxStateType } from 'src/store/reducers';

import './TopUserBar.scss';
import Feedback from '../Feedback';
import SmallButton from '../SmallButton';

const TopUserBar = () => {
  const [topBarOpened, setTopBarOpened] = useState(false);
  const [coins, setCoins] = useState(-1);
  const [elo, setElo] = useState(-1);

  const { appView, isSocketOpen, username } = useSelector(
    (state: ReduxStateType) => ({
      appView: state.app.appView,
      isSocketOpen: state.app.isSocketOpen,
      username: state.user.username,
    }),
  );

  const dispatch = useDispatch();

  const logout = async () => {
    const confirmed = confirm('Are you sure you want to logout?');
    if (!confirmed) {
      return false;
    }
    await signOut();

    const data = await fetchServer({
      url: `${backendUrl}/logout`,
      body: {},
    });
    if (data.result === 'OK') {
      playSound('backClick');
      dispatch(setAppView('login'));
      dispatch(setUserName(''));
    }
  };

  const userInfoSocketListener = (event: any) => {
    const action = JSON.parse(event.data);
    const { type, payload } = action;

    switch (type) {
      case 'SEND_USER_INFO': {
        const { coins, elo } = payload;
        setCoins(coins);
        setElo(elo);
        break;
      }

      default:
        break;
    }
  };

  const getUserInfo = async () => {
    if (socket.instance.readyState === WS_READY_STATES.OPEN) {
      socket.instance.send(
        JSON.stringify({
          type: 'GET_USER_INFO',
        }),
      );
    }
  };

  useEffect(() => {
    if (topBarOpened === true && appView === 'game') {
      setTopBarOpened(false);
    } else if (topBarOpened === false) {
      setTopBarOpened(true);
    }
  }, [appView]);

  useEffect(() => {
    if (isSocketOpen) {
      socket.addEvent({
        name: 'UserInfo',
        type: 'message',
        handler: userInfoSocketListener,
      });

      getUserInfo();

      return () => {
        socket.removeEvent({
          name: 'UserInfo',
          type: 'message',
          handler: userInfoSocketListener,
        });
      };
    } else {
      // Remove coins
      setCoins(-1);
    }
  }, [isSocketOpen]);

  return (
    <div className="TopUserBar">
      <div
        className={['toggle', topBarOpened && 'opened'].join(' ')}
        onClick={() => setTopBarOpened((prevState) => !prevState)}
      >
        V
      </div>
      <div className={['bar', topBarOpened && 'opened'].join(' ')}>
        <div className="left-side">
          <VolumeAdjuster soundType="effect" />
          <VolumeAdjuster soundType="music" />
        </div>
        <div className="right-side">
          {elo >= 0 && <div>{elo}🏅</div>}
          {coins >= 0 && <div>{coins}💰</div>}
          {username && <div className="username">{username}</div>}
          <Feedback />
          {appView !== 'login' && (
            <div>
              <SmallButton onClick={logout}>Logout</SmallButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopUserBar;
