import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { playSound } from 'src/modules/soundHandler';
import { setAppView } from 'src/store/app/actions';
import BlackOverlay from 'src/components/common/BlackOverlay';

import './SocketReconnector.scss';

import socket, { WS_READY_STATES } from './websocket';
import type { ReduxStateType } from 'src/store/reducers';

const SocketReconnector = () => {
  const [reconnecting, setReconnecting] = useState(false);
  const [socketClosed, setSocketClosed] = useState(false);
  const [reason, setReason] = useState('');

  const dispatch = useDispatch();

  const { curView, battleId } = useSelector((state: ReduxStateType) => ({
    curView: state.app.appView,
    battleId: state.app.battleId,
  }));

  const attemptReconnect = async () => {
    setReconnecting(true);
    socket.reconnect();
    const socketStatus = await socket.waitForSocketStatus();

    if (socketStatus === WS_READY_STATES.OPEN) {
      if (curView === 'game') {
        socket.instance.send(
          JSON.stringify({
            type: 'REJOIN_GAME',
            payload: {
              battleId,
            },
          }),
        );
      }
      setSocketClosed(false);
    }
    setReconnecting(false);
  };

  const onCloseEvent = (event: any) => {
    setReason(event.reason || '');
    setSocketClosed(true);
    playSound('connectionLost');
    dispatch(setAppView('home'));
  };

  useEffect(() => {
    socket.addEvent({
      name: 'SocketReconnector',
      type: 'close',
      handler: onCloseEvent,
    });
    return () => {
      socket.removeEvent({
        name: 'SocketReconnector',
        type: 'close',
        handler: onCloseEvent,
      });
    };
  }, []);

  return socketClosed ? (
    <BlackOverlay>
      <div className="SocketReconnector">
        <div className="reconnect-box">
          <p>Disconnected from the server...</p>
          {reason ? (
            <p className="note">Reason: {reason}</p>
          ) : (
            <p className="note">
              Try refreshing the website if you can not reconnect.
            </p>
          )}
          {reconnecting ? (
            <div className="connecting">Connecting...</div>
          ) : (
            <button onClick={attemptReconnect}>Reconnect</button>
          )}
        </div>
      </div>
    </BlackOverlay>
  ) : null;
};

export default SocketReconnector;
