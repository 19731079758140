import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from 'firebase/auth';
import type { User } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';

import { backendUrl, fetchServer } from 'src/utils/serverRequests';
import { isDebugMode } from 'src/modules/debugMode';
import store from 'src/store';
import { showPopup } from 'src/utils/popups';

import firebaseApp from './index';

const auth = getAuth(firebaseApp);

export const waitForFirebaseInit = (callback: (user: User | null) => void) => {
  const unsubscribe = onAuthStateChanged(auth, (user) => {
    unsubscribe();
    callback(user);
  });
};

export const getFirebaseUserToken = async (): Promise<string | undefined> => {
  const token = await auth.currentUser?.getIdToken(true);
  return token;
};

export const registerEmail = async (email: string, password: string) => {
  try {
    const credentials = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );
    sendEmail();
    const token = await credentials.user.getIdToken(true);
    return token;
  } catch (error) {
    if (isDebugMode()) console.log('Error:', error);
    if (error instanceof FirebaseError) {
      showPopup(store.dispatch, {
        message: error.code,
      });
    }
  }
};

export const loginEmail = async (email: string, password: string) => {
  try {
    const credentials = await signInWithEmailAndPassword(auth, email, password);
    const token = await credentials.user.getIdToken(true);
    return token;
  } catch (e: any) {
    if (isDebugMode()) console.log('Error:', e);
    showPopup(store.dispatch, {
      message: e.code,
    });
  }
};

interface VerifyFirebaseTokenReturnType {
  status: 'OK';
  username: string;
  emailVerified: boolean;
}
export const verifyFirebaseToken = async (
  token: string,
): Promise<VerifyFirebaseTokenReturnType | false> => {
  const data = await fetchServer({
    url: `${backendUrl}/verifyAuthToken`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: {},
  });
  if (data?.result === 'OK') {
    return {
      status: 'OK',
      username: data.username,
      emailVerified: data.email_verified,
    };
  }

  return false;
};

export const sendEmail = async (): Promise<boolean> => {
  const user = auth.currentUser;
  if (user) {
    try {
      await sendEmailVerification(user);
      return true;
    } catch (e) {
      console.warn('e', e);
    }
  } else {
    console.warn('User not defiend');
  }
  return false;
};

export const isEmailVerified = async (): Promise<boolean> => {
  const user = auth.currentUser;
  if (user) {
    await user.reload();
    return user.emailVerified;
  }
  return false;
};

export const signOut = async () => {
  await auth.signOut();
};

export const resetPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (error) {
    if (error instanceof FirebaseError) {
      return error.code;
    }

    console.error('Unknown error:', error);
  }

  return false;
};
