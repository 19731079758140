import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyDsJ82OaNYdzM7mo42cKJxBzU9FK2PU9rs',
  authDomain: 'doteric-games.firebaseapp.com',
  projectId: 'doteric-games',
  storageBucket: 'doteric-games.appspot.com',
  messagingSenderId: '390944585166',
  appId: '1:390944585166:web:00e39063f8b8b53212a79f',
  //measurementId: "G-V47LY6Q8J5"
};

const app = initializeApp(firebaseConfig);

export default app;
