import React, { useEffect, useState } from 'react';

import type { GameNameType } from 'src/games/types';
import socket from 'src/components/common/WebSocketConnection/websocket';
import { playSound } from 'src/modules/soundHandler';
import BlackOverlay from 'src/components/common/BlackOverlay/BlackOverlay';

import styles from './ReadyCheck.module.scss';

interface ReadyCheckPropsInterface {
  checkId: string;
  duration: number;
  games: GameNameType[];
}

const ReadyCheck = ({
  checkId,
  duration,
  games,
}: ReadyCheckPropsInterface): React.ReactElement => {
  const [readyTimer, setReadyTimer] = useState(0);
  const [readyAccepted, setReadyAccepted] = useState(false);

  let readyCheckTimeout: NodeJS.Timeout | null;

  const readyTimeoutTick = (timeout: number) => {
    setReadyTimer(timeout - 1);
    readyCheckTimeout = setTimeout(() => {
      if (timeout > 1) {
        readyTimeoutTick(timeout - 1);
      }
    }, 1000);
  };

  const activateReadyTimer = (timeout: number) => {
    readyTimeoutTick(timeout);
  };

  useEffect(() => {
    activateReadyTimer(duration);
    playSound('gameFound');
    return () => {
      readyCheckTimeout && clearTimeout(readyCheckTimeout);
    };
  }, []);

  const readyClick = () => {
    socket.instance.send(
      JSON.stringify({
        type: 'ACCEPT_MATCHMAKING_READY_CHECK',
        payload: {
          readyCheckId: checkId,
        },
      }),
    );
    setReadyAccepted(true);
  };

  return (
    <div className="ReadyCheck">
      <BlackOverlay>
        <div className={styles.readycheckModal}>
          <div>Game found! ({checkId})</div>
          <div>Games: {games.join(', ')}</div>
          <div>{readyTimer}</div>
          {!readyAccepted && <button onClick={readyClick}>Ready</button>}
        </div>
      </BlackOverlay>
    </div>
  );
};

export default ReadyCheck;
