import shuffleArray from 'src/utils/shuffleArray';

import selectClick from './sounds/BUTTON_TO_SELECT_MODE_CLICK.wav';
import backClick from './sounds/GO_BACK_CLICK.wav';
import tileGrab from './sounds/GRABBING_TILE_WITH_TILE_PLACE.wav';
import tilePlace from './sounds/PLACING_TILE_ON_BOARD_1.wav';
import tilePlace2 from './sounds/PLACING_TILE_ON_BOARD_2.wav';
import tilesSplit from './sounds/TILES_SPLIT.wav';
import myTurnStart from './sounds/TURN_COUNTDOWN_1.wav';
import countdown5sec from './sounds/5_SEC_COUNTDOWN_SINGLE_BEEP.wav';
import tileDraw from './sounds/TILES_DRAW.wav';
import tilesSubmitted from './sounds/TURN_SUBMITTED_SUCCESSFULLY.wav';
import placedTilesCancel from './sounds/PLACED_TILES_CANCELLED.wav';
import errorPopup from './sounds/ERROR_MESSSAGE_POP_UP.wav';
import successPopup from './sounds/TURN_COUNTDOWN_2.wav';
import tilesSort from './sounds/TILES_SORT.wav';
import connectionLost from './sounds/LOST_CONNECTION_FROM_SERVER.wav';
import playerJoinedLobby from './sounds/PLAYER_JOINED_THE_LOBBY.wav';
import playerLeftLobby from './sounds/PLAYER_LEFT_THE_LOBBY.wav';
import gameEnd from './sounds/GAME_END_SOUND_WIN.wav';
import gameFound from './sounds/GAME_FOUND.wav';
// Retro snake
import snakeDie from './sounds/retroSnake/SnakeDie.wav';
import snakeEat from './sounds/retroSnake/SnakeEat.wav';
import snakeMove from './sounds/retroSnake/SnakeMove.wav';
// Speedpoly
import diceRoll from './sounds/speedpoly/rolling_dice.mp3';
import purchase from './sounds/speedpoly/purchase.mp3';
import hotelBellDing from './sounds/speedpoly/hotel_bell_ding.mp3';
import levelUp2 from './sounds/speedpoly/level_up_2.mp3';
import openNewLevel from './sounds/speedpoly/open_new_level.mp3';

// Music
import gameMusicLoop3 from './music/game-music-loop-3-144252.mp3';
import gameMusicLoop5 from './music/game-music-loop-5-144569.mp3';
import gameMusicLoop9 from './music/game-music-loop-9-145494.mp3';
import gameMusicLoop19 from './music/game-music-loop-19-153393.mp3';
import gameMusicLoop20 from './music/game-music-loop-20-156390.mp3';
import gamingMusic2 from './music/gaming-music-2-145874.mp3';
import gameMusic from './music/game-music-150676.mp3';

const SOUNDS = {
  selectClick,
  backClick,
  tileGrab,
  tilePlace,
  tilePlace2,
  tilesSplit,
  myTurnStart,
  countdown5sec,
  tileDraw,
  tilesSubmitted,
  placedTilesCancel,
  errorPopup,
  successPopup,
  tilesSort,
  connectionLost,
  playerJoinedLobby,
  playerLeftLobby,
  gameEnd,
  gameFound,
  // Retro Snake game
  snakeDie,
  snakeEat,
  snakeMove,
  // Speedpoly
  diceRoll,
  purchase,
  hotelBellDing,
  levelUp2,
  openNewLevel,
};

const MUSIC = shuffleArray([
  gameMusicLoop3,
  gameMusicLoop5,
  gameMusicLoop9,
  gameMusicLoop19,
  gameMusicLoop20,
  gamingMusic2,
  gameMusic,
]);

const soundSettings = {
  // TODO: Consider adding effectSoundEnabled and musicSoundEnabled true/false
  effectVolume: 0.3,
  musicVolume: 0.3,
  musicRate: 1,
};

export const setVolume = (
  type: 'effect' | 'music',
  volume: number,
  unpause = true
): void => {
  if (type === 'effect') {
    soundSettings.effectVolume = volume;
  } else if (type === 'music') {
    soundSettings.musicVolume = volume;
    if (musicLoop.audio) {
      musicLoop.audio.volume = volume;

      if (musicLoop.audio.paused === false && volume === 0) {
        musicLoop.audio.pause();
      } else if (musicLoop.audio.paused && unpause) {
        musicLoop.audio.play();
      }
    }
  }
};

export const playSound = (soundName: keyof typeof SOUNDS): void => {
  if (soundName in SOUNDS && SOUNDS[soundName]) {
    if (soundSettings.effectVolume > 0) {
      const sound = new Audio(SOUNDS[soundName]);
      sound.volume = soundSettings.effectVolume;
      sound.play().catch((error) => console.warn('playSound', error));
    }
  } else {
    console.log('Sound not found:', soundName);
  }
};

interface MusicLoopType {
  audio: null | HTMLAudioElement;
  iteration: number;
}
const musicLoop: MusicLoopType = {
  audio: null,
  iteration: 0,
};

const setupNewMusic = () => {
  musicLoop.audio = new Audio(MUSIC[musicLoop.iteration]);
  musicLoop.audio.volume = soundSettings.musicVolume;
  musicLoop.audio.playbackRate = soundSettings.musicRate;

  musicLoop.audio.onended = () => {
    musicLoop.iteration += 1;
    if (!MUSIC[musicLoop.iteration]) {
      // Restart music
      musicLoop.iteration = 0;

      // TODO: Reshuffle maybe?
    }

    setupNewMusic();
    musicLoop.audio?.play();
  };
};

let userInteracted = false;
export const startMusicLoop = (autoStart = true) => {
  if (musicLoop.audio === null) {
    setupNewMusic();

    const startMusic = () => {
      if (autoStart === true) {
        musicLoop.audio?.play();
      }
      userInteracted = true;

      window.removeEventListener('click', startMusic, false);
    };
    window.addEventListener('click', startMusic, false);
  } else if (userInteracted === true) {
    musicLoop.audio.play();
  }
};

export const pauseMusicLoop = () => {
  musicLoop.audio?.pause();
};

export const setMusicSpeed = (speed: number) => {
  if (musicLoop.audio) {
    soundSettings.musicRate = speed;
    musicLoop.audio.playbackRate = speed;
  }
};

let musicStateChangeTimer: NodeJS.Timer | null = null;
// Pause music when leaving page
window.addEventListener('visibilitychange', () => {
  musicStateChangeTimer && clearTimeout(musicStateChangeTimer);

  // TODO: Cut off sound volume smoothly
  if (document.visibilityState === 'visible') {
    if (soundSettings.musicVolume > 0 && musicLoop.audio?.paused === true) {
      musicStateChangeTimer = setTimeout(() => {
        startMusicLoop();
      }, 200);
    }
  } else {
    if (musicLoop.audio?.paused === false) {
      musicStateChangeTimer = setTimeout(() => {
        musicLoop.audio?.pause();
      }, 200);
    }
  }
});
