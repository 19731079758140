import React, { useEffect, useState } from 'react';
import type { SyntheticEvent } from 'react';

import './CreateLobby.scss';

import socket from 'src/components/common/WebSocketConnection/websocket';
import { playSound } from 'src/modules/soundHandler';
import { extractSettings } from 'src/components/gamesCreator/settingsForGames';
// eslint-disable-next-line max-len
import type { GameType } from 'src/components/gamesCreator/GameSquare/GameSquare';
// eslint-disable-next-line max-len
import { calculateNeededPlayers } from 'src/views/Home/CreateLobby/utils';
import OptionBox from 'src/components/common/OptionBox';
import GameSquare from 'src/components/gamesCreator/GameSquare';
import { GAMES_LIST } from 'src/games/types';
import type { GameNameType } from 'src/games/types';

const CreateLobby = () => {
  const [isPrivateLobby, setIsPrivateLobby] = useState(true);
  const [games, setGames] = useState<GameType[]>([]);
  const [playersNeededState, setPlayersNeededState] = useState('');
  const [playersRequirements, setPlayersRequirements] = useState({
    min: 0,
    max: 0,
  });

  const createLobby = (e: SyntheticEvent) => {
    e.preventDefault();

    const playersNeeded =
      parseInt(playersNeededState) || calculateNeededPlayers(games).max;
    const gamesWithDefault = games.map((game) => ({
      name: game.name,
      settings: extractSettings(game),
    }));

    socket.sendJSON({
      type: 'CREATE_LOBBY',
      payload: {
        playersNeeded,
        games: gamesWithDefault,
        isPrivateLobby,
      },
    });

    playSound('selectClick');
  };

  const addGame = (name: GameNameType) => {
    setGames((prev) => [
      ...prev,
      {
        name,
        settings: {},
      },
    ]);
  };

  const removeGame = (gameNr: number) => {
    setGames((prev) => {
      const updatedGames = [
        ...prev.slice(0, gameNr),
        ...prev.slice(gameNr + 1, prev.length),
      ];
      return updatedGames;
    });
  };

  const updateGameSetting = (
    gameNr: number,
    settingName: string,
    value: string,
  ) => {
    setGames((prev) => {
      const updateGames = [...prev];
      updateGames[gameNr].settings[settingName] = value;
      return updateGames;
    });
  };

  const moveAction = (gameNr: number, where: 'left' | 'right') => {
    const newIndex = gameNr + (where === 'left' ? -1 : 1);
    if (games[newIndex]) {
      setGames((prev) => {
        const updateGames = [...prev];
        [updateGames[gameNr], updateGames[newIndex]] = [
          updateGames[newIndex],
          updateGames[gameNr],
        ];
        return updateGames;
      });
    }
  };

  useEffect(() => {
    const neededPlayers = calculateNeededPlayers(games);
    setPlayersRequirements(neededPlayers);
  }, [games]);

  // TODO: Needed game settings to be sent from server?
  return (
    <div className="CreateLobby">
      <>
        <div className="selected-games-holder">
          <div className="selected-games">
            {games.map((game, index) => (
              <GameSquare
                key={`${game.name}-${index}`}
                gameNr={index}
                type="selected"
                game={game}
                removeGame={removeGame}
                updateGameSetting={updateGameSetting}
                moveAction={moveAction}
                disabledMoveLeft={!games[index - 1]}
                disabledMoveRight={!games[index + 1]}
              />
            ))}
          </div>
        </div>
        <div className="games-list-title">Available games:</div>
        <div className="games-list-holder">
          <div className="games-list">
            {GAMES_LIST.map((game) => {
              return (
                <GameSquare
                  key={game}
                  size="large"
                  type="in-list"
                  game={{ name: game, settings: {} }}
                  addGame={addGame}
                />
              );
            })}
          </div>
        </div>
        <OptionBox
          name="Max Players"
          type="number"
          value={playersNeededState}
          onChange={(e) => setPlayersNeededState(e.target.value)}
          placeholder={String(playersRequirements.max)}
          min={playersRequirements.min}
          max={playersRequirements.max}
        />
        <div className="create-game">
          <form onSubmit={createLobby}>
            <div style={{ marginBottom: '10px' }}>
              <OptionBox
                type="select"
                name="Lobby Type"
                value={isPrivateLobby ? 'private' : 'public'}
                onChange={(e) =>
                  setIsPrivateLobby(e.target.value === 'private' ? true : false)
                }
                options={['private', 'public']}
              />
            </div>
            <button disabled={games.length <= 0}>Create lobby</button>
          </form>
        </div>
      </>
    </div>
  );
};

export default CreateLobby;
