import React from 'react';

import BlackOverlay from '../BlackOverlay';

import './LoadingSquares.scss';

const LoadingSquares = () => {
  return (
    <BlackOverlay>
      <div className="LoadingSquares">
        <div className="square" />
        <div className="square" />
        <div className="square" />
        <div className="square" />
      </div>
    </BlackOverlay>
  );
};

export default LoadingSquares;
