// eslint-disable-next-line max-len
import { SETTINGS_FOR_GAMES } from 'src/components/gamesCreator/settingsForGames';
// eslint-disable-next-line max-len
import type { GameType } from 'src/components/gamesCreator/GameSquare/GameSquare';

// TODO: optimize somehow?
export const calculateNeededPlayers = (gamesList: GameType[]) => {
  const neededPlayers = {
    min: 0,
    max: 0,
  };

  for (const game of gamesList) {
    const playersNeededSetting = SETTINGS_FOR_GAMES[game.name].find(
      (setting) => setting.name === 'playersNeeded',
    );
    if (!playersNeededSetting) continue;

    if (playersNeededSetting.min > neededPlayers.min) {
      neededPlayers.min = playersNeededSetting.min;
    }
    if (!neededPlayers.max || playersNeededSetting.max < neededPlayers.max) {
      neededPlayers.max = playersNeededSetting.max;
    }
  }

  return neededPlayers;
};
