import React, { useState, useEffect } from 'react';

const Timer = (): React.ReactElement => {
  const [count, setCount] = useState(-1);

  const requestRef = React.useRef<number>(0);

  let timeout: NodeJS.Timeout;

  const tick = () => {
    setCount((prev) => prev+1);

    timeout = setTimeout(() => {
      requestRef.current = requestAnimationFrame(tick);
    }, 1000);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(tick);

    return () => {
      cancelAnimationFrame(requestRef.current);
      clearTimeout(timeout);
    };
  }, []);

  const mins = Math.floor(count / 60);
  const seconds = count % 60;

  return (
    <span className="Timer">
      {mins < 10 ? `0${mins}` : mins}:{seconds < 10 ? `0${seconds}` : seconds}
    </span>
  );
};

export default Timer;
