import React, { useState } from 'react';
import type { FormEvent } from 'react';
import { useDispatch } from 'react-redux';

import BlackOverlay from 'src/components/common/BlackOverlay';
import Modal from 'src/components/common/Modal';

import { resetPassword } from 'src/modules/firebase/utils';
import { showPopup } from 'src/utils/popups';

interface ResetPasswordModalPropsInterface {
  setFetching: (value: boolean) => void;
  onCloseModal: () => void;
}
const ResetPasswordModal = ({
  setFetching,
  onCloseModal,
}: ResetPasswordModalPropsInterface) => {
  const [resetPasswordEmail, setResetPasswordEmail] = useState('');
  const [resetPasswordView, setResetPasswordView] = useState<'email' | 'code'>(
    'email',
  );

  const dispatch = useDispatch();

  const resetPasswordModalHandler = async (event: FormEvent) => {
    event.preventDefault();

    if (resetPasswordView === 'email') {
      setFetching(true);
      const resetPasswordResult = await resetPassword(resetPasswordEmail);
      setFetching(false);
      if (resetPasswordResult === true) {
        setResetPasswordView('code');
      } else {
        showPopup(dispatch, {
          message: resetPasswordResult || 'Unknown error',
        });
      }
    }
  };

  return (
    <BlackOverlay>
      <Modal onCloseClick={onCloseModal}>
        <form onSubmit={resetPasswordModalHandler}>
          {resetPasswordView === 'email' && (
            <>
              <input
                type="email"
                placeholder="Email"
                value={resetPasswordEmail}
                onChange={(e) => setResetPasswordEmail(e.target.value)}
                required
              />
              <button>Submit</button>
            </>
          )}
          {resetPasswordView === 'code' && (
            <>
              <p>Password reset link has been sent to your email:</p>
              <p>
                <b>{resetPasswordEmail}</b>
              </p>
            </>
          )}
        </form>
      </Modal>
    </BlackOverlay>
  );
};

export default ResetPasswordModal;
