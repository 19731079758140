import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './BattleFinishedOverlay.module.scss';

import { setAppView } from 'src/store/app/actions';

import socket from 'src/components/common/WebSocketConnection/websocket';
import { playSound } from 'src/modules/soundHandler';
import BlackOverlay from 'src/components/common/BlackOverlay/BlackOverlay';

const BattleFinishedOverlay = () => {
  const [overlayOpened, setOverlayOpened] = useState(false);
  const [winnersNick, setWinnersNick] = useState<string[]>([]);
  const [reason, setReason] = useState('');
  const dispatch = useDispatch();

  const gameFinishedSocketListener = (event: any) => {
    const action = JSON.parse(event.data);
    const { type, payload } = action;

    switch (type) {
      case 'BATTLE_FINISHED': {
        const { winners, reason } = payload;
        setWinnersNick(winners);
        setReason(reason);
        playSound('gameEnd');
        setOverlayOpened(true);
        break;
      }

      default:
        break;
    }
  };

  useEffect(() => {
    socket.addEvent({
      name: 'GameFinishedOverlay',
      type: 'message',
      handler: gameFinishedSocketListener,
    });
    return () => {
      socket.removeEvent({
        name: 'GameFinishedOverlay',
        type: 'message',
        handler: gameFinishedSocketListener,
      });
    };
  }, []);

  if (winnersNick.length > 0) {
    return overlayOpened === true ? (
      <BlackOverlay>
        <div className={styles.BattleFinishedBox}>
          <div className={styles.top}>Battle finished!</div>
          <div className={styles.bottom}>
            <div>Reason: {reason}</div>
            <div>
              <span>{winnersNick.length > 1 ? 'Winners' : 'Winner'}: </span>
              <span className={styles.winner}>{winnersNick.join(', ')}</span>
            </div>
            <button onClick={() => setOverlayOpened(false)}>Close</button>
          </div>
        </div>
      </BlackOverlay>
    ) : (
      <button
        className={styles.BattleFinishedButton}
        onClick={() => dispatch(setAppView('home'))}
      >
        Go to lobby
      </button>
    );
  } else {
    return null;
  }
};

export default BattleFinishedOverlay;
