export const GAMES_LIST = [
  'rummikub',
  'abcd',
  'tictactoe',
  'retrosnake',
  'spotmatch',
  'speedmaze',
  'speedpoly',
] as const;

export type GameNameType = (typeof GAMES_LIST)[number];
