import React from 'react';

import { playSound } from 'src/modules/soundHandler';

import './BackButton.scss';

interface BackButtonPropsInterface {
  onClick: () => any;
  [key: string]: any;
}

const BackButton = ({
  onClick,
  ...otherProps
}: BackButtonPropsInterface) => {
  const buttonClick = () => {
    onClick && onClick();
    playSound('backClick');
  };

  return (
    <div
      className="BackButton"
      onClick={buttonClick}
      {...otherProps}
    >
      {'<'}
    </div>
  );
};

export default BackButton;
