import React, { useCallback, useEffect } from 'react';

import socket from './websocket';

import { isDebugMode } from 'src/modules/debugMode';
import useSocketListener from 'src/hooks/useSocketListener';
import SocketReconnector from './SocketReconnector';

const WebSocketConnection = () => {
  const socketMessageEvent = (event: any) => {
    console.log('Message from server ', event.data);
  };
  const socketErrorEvent = (error: any) => {
    console.error('Socket error:', error);
  };

  useSocketListener(
    'WebSocketConnection',
    'error',
    useCallback(socketErrorEvent, [])
  );
  if (isDebugMode()) {
    useSocketListener(
      'WebSocketConnection',
      'message',
      useCallback(socketMessageEvent, [])
    );
  }

  useEffect(() => {
    socket.initConnection();

    return () => {
      socket.closeConnection();
    };
  }, []);

  return <SocketReconnector />;
};

export default WebSocketConnection;
