import React, { useCallback, useState } from 'react';
import useSocketListener from 'src/hooks/useSocketListener';
import BackButton from '../BackButton';
import InLobbyBox from '../InLobbyBox';

interface LobbyControllerPropsType {
  children: React.ReactNode;
}

const LobbyController = ({ children }: LobbyControllerPropsType) => {
  const [joinCode, setJoinCode] = useState('');
  const [minPlayers, setMinPlayers] = useState(-1);
  const [maxPlayers, setMaxPlayers] = useState(-1);

  const homeSocketListener = (event: any) => {
    const action = JSON.parse(event.data);
    const { type, payload } = action;

    switch (type) {
      case 'CREATE_LOBBY_SUCCESS':
      case 'ALREADY_IN_THIS_LOBBY':
      case 'JOIN_LOBBY_SUCCESS': {
        setJoinCode(payload.joinCode);
        setMinPlayers(payload.playerCountRequirement.min);
        setMaxPlayers(payload.playerCountRequirement.max);
        break;
      }

      default:
        break;
    }
  };

  const onCloseEvent = () => {
    setJoinCode('');
  };

  useSocketListener('Home', 'message', useCallback(homeSocketListener, []));
  useSocketListener('Home', 'close', useCallback(onCloseEvent, []));

  if (joinCode) {
    return (
      <>
        <div className="backbutton-holder">
          <BackButton onClick={() => setJoinCode('')} />
        </div>
        <InLobbyBox
          joinCode={joinCode}
          minPlayers={minPlayers}
          maxPlayers={maxPlayers}
          leaveLobby={() => setJoinCode('')}
        />
      </>
    );
  }

  return <>{children}</>;
};

export default LobbyController;
