export const backendUrl = process.env.NODE_ENV === 'development' ?
  'http://localhost:8080/backend' :
  `https://${window.location.hostname}/backend`;

interface FetchServerParams {
  url?: string;
  path?: string;
  headers?: Record<string, any>;
  body?: Record<string, any>;
}

export const fetchServer = async ({
  url,
  path,
  headers,
  body
}: FetchServerParams) => {
  const requestUrl = url || `${backendUrl}${path}`;
  const response = await fetch(requestUrl, {
    method: body ? 'POST' : 'GET',
    headers: {
      ...(body ? {
        'Content-Type': 'application/json',
      } : {}),
      ...headers,
    },
    credentials:
      process.env.NODE_ENV === 'development' ? 'include' : 'same-origin',
    body: JSON.stringify(body),
  });
  return await response.json();
};
