import React from 'react';

import { playSound } from 'src/modules/soundHandler';

import './SquareButton.scss';

interface SquareButtonPropsInterface {
  image?: any;
  text: string;
  colorPreset?: 'white' | 'red' | 'blue' | 'green' | 'yellow' | 'lightblue';
  noHeight?: boolean;
  onClick?: () => any;
  [key: string]: any; // Any other props
}

const SquareButton = ({
  image,
  text,
  colorPreset = 'white',
  noHeight = false,
  onClick,
  ...otherProps
}: SquareButtonPropsInterface) => {
  const buttonClick = () => {
    if (onClick) {
      onClick();
      playSound('selectClick');
    }
  };

  return (
    <button
      style={noHeight ? { height: 'auto' } : {}}
      className={['SquareButton', colorPreset].filter(Boolean).join(' ')}
      onClick={buttonClick}
      {...otherProps}
    >
      <div className="image-holder">{image && <img src={image} />}</div>
      <div className="text-holder">{text}</div>
    </button>
  );
};

export default SquareButton;
