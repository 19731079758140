import React, { useEffect, useState } from 'react';

import LoadingSquares from 'src/components/common/LoadingSquares';
import type { PreloadableComponent } from 'src/utils/reactLazyWithPreload';

interface GamesPreloaderParamsType {
  components: PreloadableComponent<() => React.ReactElement>[];
  gamesLoadedCallback?: () => void;
}

const GamesPreloader = ({
  components,
  gamesLoadedCallback,
}: GamesPreloaderParamsType) => {
  const [componentsLoaded, setComponentsLoaded] = useState(false);

  const waitForComponents = async (
    componentsList: GamesPreloaderParamsType['components'],
  ) => {
    const promises = componentsList.map((Component) => Component.preload());
    await Promise.all(promises);
    setComponentsLoaded(true);
  };

  useEffect(() => {
    if (componentsLoaded) gamesLoadedCallback && gamesLoadedCallback();
    else waitForComponents(components);
  }, [componentsLoaded]);

  if (!componentsLoaded) return <LoadingSquares />;

  return null;
};

export default GamesPreloader;
