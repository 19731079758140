import React, { useEffect, useRef, useState } from 'react';

import styles from './MovieCountdown.module.scss';
import { playSound } from 'src/modules/soundHandler';

interface MovieCountdownPropsType {
  duration?: number;
  onCountdownEnd?: () => void;
}

const MovieCountdown = ({
  duration = 3,
  onCountdownEnd,
}: MovieCountdownPropsType) => {
  const [countdown, setCountdown] = useState(duration);

  const countdownTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (countdown < 3) {
      playSound('countdown5sec');
    }

    if (countdown > -1) {
      countdownTimeoutRef.current = setTimeout(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else {
      onCountdownEnd?.();
    }

    return () => {
      countdownTimeoutRef.current && clearTimeout(countdownTimeoutRef.current);
    };
  }, [countdown]);

  return <div className={styles.MovieCountdown}>{countdown}</div>;
};

export default MovieCountdown;
