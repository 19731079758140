import React, { useCallback, useEffect, useState } from 'react';

import socket from 'src/components/common/WebSocketConnection/websocket';
import useSocketListener from 'src/hooks/useSocketListener';
import LoadingSquares from 'src/components/common/LoadingSquares';

import './Leaderboard.scss';

interface LeaderboardUsersInterface {
  nickname: string;
  battlesWon: number;
  battlesFinished: number;
  battlesPlayed: number;
  elo: number;
}

const Leaderboard = () => {
  const [users, setUsers] = useState<LeaderboardUsersInterface[] | null>(null);
  const [loading, setLoading] = useState(true);

  const leaderboardSocketListener = (event: any) => {
    const action = JSON.parse(event.data);
    const { type, payload } = action;

    switch (type) {
      case 'SEND_LEADERBOARD': {
        const { leaderboard } = payload;
        setUsers(leaderboard);
        setLoading(false);
        break;
      }

      default:
        break;
    }
  };

  useSocketListener(
    'Leaderboard',
    'message',
    useCallback(leaderboardSocketListener, []),
  );

  const getLeaderboard = () => {
    socket.instance.send(
      JSON.stringify({
        type: 'GET_LEADERBOARD',
      }),
    );
  };

  useEffect(() => {
    getLeaderboard();
  }, []);

  if (loading) {
    return <LoadingSquares />;
  }

  return (
    <div className="Leaderboard">
      {users && users.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Nr.</th>
              <th>Nickname</th>
              <th>Elo</th>
              <th>Won</th>
              <th>Finished</th>
              <th>Played</th>
              <th>WinFin Ratio</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={`${user.nickname}-${index}`}>
                <td>{index + 1}.</td>
                <td>{user.nickname || '<NoNickname>'}</td>
                <td>{user.elo}</td>
                <td>{user.battlesWon}</td>
                <td>{user.battlesFinished}</td>
                <td>{user.battlesPlayed}</td>
                <td>
                  {user.battlesFinished > 0
                    ? Math.round((user.battlesWon / user.battlesFinished) * 100)
                    : '-'}
                  %
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>Leaderboard not available</div>
      )}
    </div>
  );
};

export default Leaderboard;
