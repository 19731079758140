import { useRef, useEffect } from 'react';

import socket from '../../components/common/WebSocketConnection/websocket';

const useSocketListener = (
  name: string,
  type: 'message' | 'close' | 'error',
  handler: (event: any) => void
) => {
  // Create a ref that stores handler
  const savedHandler = useRef<typeof handler>();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const eventHandler = (event: any) => savedHandler?.current?.(event);
    socket.addEvent({
      name,
      type,
      handler: eventHandler
    });
    return () => {
      socket.removeEvent({
        name,
        type,
        handler: eventHandler
      });
    };
  }, [name]);
};

export default useSocketListener;
