import React from 'react';
import FlipMove from 'react-flip-move';

import styles from './Leaderboard.module.scss';

export interface LeaderboardUserType {
  nickname: string;
  points: number;
  addedPoints?: number;
  color: string;
}

interface LeaderboardPropsType {
  title: string;
  users: LeaderboardUserType[];
}

const Leaderboard = ({ title, users }: LeaderboardPropsType) => {
  return (
    <div className={styles.Leaderboard}>
      <div className={styles.title}>{title}</div>
      <FlipMove>
        {users.map((user, index) => (
          <div key={user.nickname} className={styles['leaderboard-row']}>
            <div className={styles.nickname}>
              {index + 1}. {user.nickname}
            </div>
            <div className={[styles.points, styles[user.color]].join(' ')}>
              {user.points}
              {typeof user.addedPoints === 'number' ? (
                <span className={styles['added-points']}>
                  {user.addedPoints >= 0 && '+'}
                  {user.addedPoints}
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
        ))}
      </FlipMove>
    </div>
  );
};

export default Leaderboard;
