declare global {
  interface Window {
    debug_mode?: boolean;
  }
}

if (process.env.NODE_ENV === 'development') {
  window.debug_mode = true;
}

export const isDebugMode = () => {
  if (window.debug_mode === true) {
    return true;
  }

  return false;
};
