import React, { useCallback, useState } from 'react';

import useSocketListener from 'src/hooks/useSocketListener';
import styles from './PlayersSidePanel.module.scss';

// TODO: Use PlayerInListType type from server
export interface PlayerType {
  nickname: string;
  points?: number;
  isMe?: boolean;
  pointsAdded?: number;
  active?: boolean;
  color?: string;
  offline?: boolean;
}

interface PlayersPanelPropsType {
  mobileView?: boolean;
}
const PlayersSidePanel = ({ mobileView = false }: PlayersPanelPropsType) => {
  const [topText, setTopText] = useState('');
  const [topBoxContent, setTopBoxContent] = useState('');
  const [players, setPlayers] = useState<PlayerType[]>([]);
  const [expandedMobileView, setExpandedMobileView] = useState(false);

  const playersSidePanelSocketListener = (event: any) => {
    const action = JSON.parse(event.data);
    const { type, payload } = action;

    switch (type) {
      case 'SEND_PLAYERS_PANEL_TOP_INFO': {
        const { text, boxContent } = payload;
        setTopText(text);
        setTopBoxContent(boxContent);
        break;
      }

      case 'SEND_PLAYERS_INFO': {
        const { players } = payload;
        setPlayers(players);
        break;
      }

      default:
        break;
    }
  };

  useSocketListener(
    'PlayersSidePanel',
    'message',
    useCallback(playersSidePanelSocketListener, [])
  );

  const expandPlayerPanel = () => {
    setExpandedMobileView(true);
  };

  // Mobile View (Minimal)
  if (mobileView && !expandedMobileView)
    return (
      <div
        className={[styles.PlayersSidePanel, styles.minimal].join(' ')}
        onClick={() => expandPlayerPanel()}
      >
        <div className={[styles.players_list, styles.mobile_view].join(' ')}>
          {players.map((player) => (
            <div
              key={player.nickname}
              className={[
                styles.player_row,
                player.isMe && styles.is_me,
                player.active && styles.active,
                player.offline && styles.offline,
              ]
                .filter(Boolean)
                .join(' ')}
            >
              <div className={styles.nickname}>{player.nickname}</div>
              <div
                className={[styles.points, player.color && styles[player.color]]
                  .filter(Boolean)
                  .join(' ')}
              >
                {player.points}
                {player.pointsAdded != undefined && (
                  <div className={styles.points_added}>
                    {player.pointsAdded >= 0 && '+'}
                    {player.pointsAdded}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.top_row}>
          <div className={styles.box}>{topBoxContent}</div>
        </div>
      </div>
    );

  // Standard View
  return (
    <div
      className={[styles.PlayersSidePanel, mobileView && styles.mobile_view]
        .filter(Boolean)
        .join(' ')}
    >
      {mobileView && (
        <button
          className={styles.close_button}
          onClick={() => setExpandedMobileView(false)}
        >
          Close
        </button>
      )}
      <div className={styles.top_row}>
        <div className={styles.text}>{topText}</div>
        <div className={styles.box}>{topBoxContent}</div>
      </div>
      <div className={styles.players_list}>
        {/* TODO: right box column name */}
        {players.map((player) => (
          <div
            key={player.nickname}
            className={[
              styles.player_row,
              player.isMe && styles.is_me,
              player.active && styles.active,
              player.offline && styles.offline,
            ]
              .filter(Boolean)
              .join(' ')}
          >
            <div className={styles.nickname}>{player.nickname}</div>
            <div
              className={[styles.points, player.color && styles[player.color]]
                .filter(Boolean)
                .join(' ')}
            >
              {player.points}
              {player.pointsAdded != undefined && (
                <div className={styles.points_added}>
                  {player.pointsAdded >= 0 && '+'}
                  {player.pointsAdded}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlayersSidePanel;
