import React from 'react';

import './ThinSeparator.scss';

interface ThinSeparatorPropsInterface {
  angle: 'horizontal' | 'vertical';
  children: React.ReactNode;
}

const ThinSeparator = ({
  angle,
  children,
}: ThinSeparatorPropsInterface) => {
  return (
    <div className={`ThinSeparator ${angle}`}>
      {children}
    </div>
  );
};

export default ThinSeparator;
