import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import socket from 'src/components/common/WebSocketConnection/websocket';
import useSocketListener from 'src/hooks/useSocketListener';
import { playSound } from 'src/modules/soundHandler';
import { setAppView } from 'src/store/app/actions';
import { setPlayedGames } from 'src/store/app/actions';
import GameSquare from 'src/components/gamesCreator/GameSquare/GameSquare';
import BlackOverlay from 'src/components/common/BlackOverlay';
import MovieCountdown from 'src/components/common/MovieCountdown';
// eslint-disable-next-line max-len
import type { GameType } from 'src/components/gamesCreator/GameSquare/GameSquare';

import './InLobby.scss';

interface InLobbyBoxPropsInterface {
  joinCode?: string;
  minPlayers: number | string;
  maxPlayers: number | string;
  leaveLobby: () => unknown;
}

const InLobbyBox = ({
  joinCode,
  minPlayers,
  maxPlayers,
  leaveLobby,
}: InLobbyBoxPropsInterface) => {
  const [players, setPlayers] = useState<
    {
      nickname: string;
      isMe: boolean;
      color: string;
      isReady: boolean;
      elo: number;
    }[]
  >([
    { nickname: '(Me)', isMe: true, color: 'green', isReady: false, elo: -1 },
  ]);
  const [games, setGames] = useState<GameType[]>([]);
  const [countdownDuration, setCountdownDuration] = useState(-1);
  const [gameStartCountdown, setGameStartCountdown] = useState(false);

  const dispatch = useDispatch();

  const inLobbySocketListener = (event: any) => {
    const action = JSON.parse(event.data);
    const { type, payload } = action;

    switch (type) {
      case 'SEND_GAMES_IN_LOBBY': {
        const { gamesInLobby } = payload;
        setGames(gamesInLobby);
        break;
      }

      case 'SEND_PLAYERS_IN_LOBBY': {
        const { playersInLobby } = payload;
        if (playersInLobby.length > players.length) {
          // Player joined
          playSound('playerJoinedLobby');
        } else if (playersInLobby.length < players.length) {
          // Player left
          playSound('playerLeftLobby');

          if (gameStartCountdown) setGameStartCountdown(false);
        }
        setPlayers(playersInLobby);
        break;
      }

      case 'LOBBY_COUNTDOWN_START': {
        const { joinCode: rJoinCode, seconds } = payload;
        if (rJoinCode === joinCode) {
          setCountdownDuration(seconds);
          setGameStartCountdown(true);
        }
        break;
      }

      case 'GAME_STARTING': {
        const { battleId, games } = payload;
        dispatch(setPlayedGames(battleId, games));
        dispatch(setAppView('game')); // TODO: Temp?
        break;
      }

      default:
        break;
    }
  };

  useSocketListener(
    'InLobbyBox',
    'message',
    useCallback(inLobbySocketListener, [players, gameStartCountdown]),
  );

  useEffect(() => {
    return () => {
      socket.sendJSON({
        type: 'LEAVE_LOBBY',
        payload: {
          joinCode,
        },
      });
    };
  }, []);

  const sendReadyStatus = (isReady: boolean) => {
    socket.sendJSON({
      type: 'READY_IN_LOBBY',
      payload: {
        joinCode,
        isReady,
      },
    });
  };

  return (
    <div className="InLobbyBox">
      <div className="games-list">
        {games.map((game, i) => (
          <GameSquare key={i} type="showcase" game={game} />
        ))}
      </div>
      <div className="lobby-details">
        <p>Waiting for players...</p>
        <div className="join-code-holder">
          <div className="text">Join code</div>
          <div className="code">{joinCode}</div>
        </div>
        <div className="basic-box">
          Players in lobby currently: <b>{players.length}</b>/{maxPlayers}
          <br />
          Minimum needed players: {minPlayers}
          <br />
          <span className="small-text">
            The battle will start when all players are ready and minimum players
            required is met.
          </span>
        </div>
        <p>Players:</p>
        {players.map((player) => (
          <div
            key={player.nickname}
            className={['basic-box', 'player-box', player.isMe && 'is-me']
              .filter(Boolean)
              .join(' ')}
          >
            <div className={['color-box', player.color].join(' ')}></div>
            <div className="elo">{player.elo >= 0 ? player.elo : ''}</div>
            <div className="player-nick">{player.nickname}</div>
            <button
              disabled={!player.isMe}
              onClick={
                player.isMe ? () => sendReadyStatus(!player.isReady) : undefined
              }
              className={['ready-button', player.isReady && 'is-ready']
                .filter(Boolean)
                .join(' ')}
            >
              {player.isMe && (
                <>
                  <span>{player.isReady ? 'Unready' : 'Ready up'}</span>
                  <br />
                </>
              )}
              <span
                className={[player.isMe && 'small-text']
                  .filter(Boolean)
                  .join(' ')}
              >
                {player.isReady ? 'Ready' : 'Not ready'}
              </span>
            </button>
          </div>
        ))}
      </div>
      {gameStartCountdown && (
        <BlackOverlay>
          <MovieCountdown
            duration={countdownDuration}
            onCountdownEnd={() => setGameStartCountdown(false)}
          />
          <button className="leave-lobby-button" onClick={leaveLobby}>
            Leave lobby
          </button>
        </BlackOverlay>
      )}
    </div>
  );
};

export default InLobbyBox;
