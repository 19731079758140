import React from 'react';
import { connect } from 'react-redux';

import './App.scss';

import WebSocketConnection from 'src/components/common/WebSocketConnection';

import Login from './views/Login';
import Home from 'src/views/Home';
import PopupMessages from 'src/components/common/PopupMessages';
import TopUserBar from 'src/components/common/TopUserBar';
import GamesCoordinator from 'src/views/GamesCoordinator';

import type { appViewType } from 'src/store/app/actions';

interface AppPropsInterface {
  view?: appViewType;
}

const viewToComponentMap = {
  login: Login,
  home: Home,
  game: GamesCoordinator,
};

const NotFound = () => <div>View not found</div>;

const App = ({ view }: AppPropsInterface) => {
  const ViewComponent = (view && viewToComponentMap[view]) || NotFound;

  return (
    <div className="App">
      {view !== 'login' && (
        <>
          <WebSocketConnection />
        </>
      )}
      <PopupMessages />
      <TopUserBar />
      <ViewComponent />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  view: state.app.appView,
});

export default connect(mapStateToProps, null)(App);
