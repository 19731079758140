import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import './index.css';
import App from './App';
import store from './store';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    /*
      TODO: Consider re-enabling React.StrictMode
      currently it breaks local dev
    */
    //<React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>,
    //</React.StrictMode>,
  );
}
