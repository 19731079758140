// eslint-disable-next-line max-len
import type { GameType } from 'src/components/gamesCreator/GameSquare/GameSquare';
import type { GameNameType } from 'src/games/types';

export interface SettingType {
  name: string;
  display: string;
  type: 'text' | 'number';
  min: number;
  max: number;
  default: number;
}

export const SETTINGS_FOR_GAMES: Record<GameNameType, SettingType[]> = {
  rummikub: [
    {
      name: 'playersNeeded',
      display: 'Players',
      type: 'number',
      min: 2,
      max: 4,
      default: 4,
    },
    {
      name: 'turnTime',
      display: 'Turn time (s)',
      type: 'number',
      min: 15,
      max: 180,
      default: 45,
    },
  ],
  abcd: [
    {
      name: 'playersNeeded',
      display: 'Players',
      type: 'number',
      min: 2,
      max: 10,
      default: 4,
    },
    {
      name: 'answerTime',
      display: 'Answer time (s)',
      type: 'number',
      min: 5,
      max: 30,
      default: 15,
    },
    {
      name: 'questionsCount',
      display: 'Questions count',
      type: 'number',
      min: 1,
      max: 20,
      default: 5,
    },
  ],
  tictactoe: [
    {
      name: 'playersNeeded',
      display: 'Players',
      type: 'number',
      min: 2,
      max: 6,
      default: 4,
    },
    {
      name: 'turnTime',
      display: 'Turn time (s)',
      type: 'number',
      min: 1,
      max: 10,
      default: 5,
    },
    {
      name: 'rounds',
      display: 'Rounds',
      type: 'number',
      min: 1,
      max: 10,
      default: 5,
    },
  ],
  retrosnake: [
    {
      name: 'playersNeeded',
      display: 'Players',
      type: 'number',
      min: 2,
      max: 6,
      default: 4,
    },
    {
      name: 'roundTime',
      display: 'Round time (s)',
      type: 'number',
      min: 15,
      max: 300,
      default: 40,
    },
    {
      name: 'speed',
      display: 'Speed',
      type: 'number',
      min: 1,
      max: 2,
      default: 2,
    },
    {
      name: 'fieldSize',
      display: 'Field size',
      type: 'number',
      min: 100,
      max: 300,
      default: 300,
    },
  ],
  spotmatch: [
    {
      name: 'playersNeeded',
      display: 'Players',
      type: 'number',
      min: 2,
      max: 8,
      default: 4,
    },
  ],
  speedmaze: [
    {
      name: 'playersNeeded',
      display: 'Players',
      type: 'number',
      min: 2,
      max: 8,
      default: 4,
    },
    {
      name: 'totalRounds',
      display: 'Total rounds',
      type: 'number',
      min: 1,
      max: 10,
      default: 5,
    },
  ],
  speedpoly: [
    {
      name: 'playersNeeded',
      display: 'Players',
      type: 'number',
      min: 2,
      max: 6,
      default: 4,
    },
  ],
};

/* eslint-disable max-len */
export const GAMES_DESCRIPTION = {
  rummikub:
    'A tile-based game, combining elements of the card game rummy and mahjong. There are 106 tiles in the game, including 104 numbered tiles (valued 1 to 13 in four different colors, two copies of each) and two jokers. Players have 14 tiles initially and take turns putting down tiles from their racks into sets (groups or runs) of at least three, drawing a tile if they cannot play. An important feature of the game is that players can work with the tiles that have already been played.',
  abcd: 'A quiz game where players answer questions selecting one correct answer from a total of 4 options.',
  tictactoe:
    'Players take turns in drawing either their shape in one square of a grid consisting of multiple squares depending on the amount of players.',
  retrosnake:
    'Fight against other snakes to gather the most points. The longest tail before the time ends wins.',
  spotmatch: 'Spot the matching symbols between two cards.',
  speedmaze: 'Find the exit of the maze before others.',
  speedpoly: 'Monopoly done quick',
};
/* eslint-enable max-len */

export const extractSettings = (game: GameType) => {
  const settings: Record<string, string | number> = {};
  for (const setting of SETTINGS_FOR_GAMES[game.name]) {
    // Do not extract playersNeeded as it's needed only to determine min/max.
    if (setting.name === 'playersNeeded') continue;

    const settingValue =
      setting.type === 'number'
        ? parseInt(game.settings[setting.name], 10)
        : game.settings[setting.name];
    settings[setting.name] = settingValue || setting.default;
  }

  return settings;
};
