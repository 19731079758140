import React, { useState } from 'react';
import type { SyntheticEvent } from 'react';

import socket from 'src/components/common/WebSocketConnection/websocket';
import { playSound } from 'src/modules/soundHandler';

import './JoinLobby.scss';

const JoinLobby = () => {
  const [joinGameCode, setJoinGameCode] = useState('');

  const joinLobby = (e: SyntheticEvent) => {
    e.preventDefault();
    socket.sendJSON({
      type: 'JOIN_LOBBY',
      payload: {
        joinCode: joinGameCode,
      },
    });

    playSound('selectClick');
    // Verify if code works and join game
  };

  return (
    <div className="JoinLobby">
      <form onSubmit={joinLobby}>
        <input
          type="text"
          placeholder="Join Code"
          value={joinGameCode}
          onChange={(e) => setJoinGameCode(e.target.value)}
          minLength={6}
          maxLength={6}
          required
        />
        <button>Join Lobby</button>
      </form>
    </div>
  );
};

export default JoinLobby;
