import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import BlackOverlay from 'src/components/common/BlackOverlay';
import LoadingSquares from 'src/components/common/LoadingSquares';
import Modal from 'src/components/common/Modal';
import {
  signOut,
  sendEmail,
  isEmailVerified,
} from 'src/modules/firebase/utils';
import { showPopup } from 'src/utils/popups';

interface ConfirmEmailPropsType {
  email: string;
  onCloseConfirmEmail: (reason: string) => void;
}

const ConfirmEmail = ({
  email,
  onCloseConfirmEmail,
}: ConfirmEmailPropsType) => {
  const [checking, setChecking] = useState(false);
  const [emailResentStatus, setEmailResentStatus] = useState<
    'none' | 'sending' | 'sent' | 'failed'
  >('none');

  const dispatch = useDispatch();

  const checkClick = async () => {
    setChecking(true);
    const verified = await isEmailVerified();
    setChecking(false);
    if (verified) onCloseConfirmEmail('confirmed');
    else {
      showPopup(dispatch, {
        message: 'Email not confirmed',
        color: 'yellow',
      });
    }
  };

  const resendEmailClick = async () => {
    setEmailResentStatus('sending');
    const emailSent = await sendEmail();
    if (emailSent) {
      setEmailResentStatus('sent');
    } else {
      setEmailResentStatus('failed');
    }

    showPopup(dispatch, {
      message: emailSent
        ? 'Email resent successfully'
        : 'Failed to resend email',
      color: emailSent ? 'blue' : 'red',
    });
  };

  const logoutClick = async () => {
    await signOut();
    onCloseConfirmEmail('logout');
  };

  return (
    <BlackOverlay>
      <Modal showCloseButton={false}>
        <p>Email needs to be verified.</p>
        <p>Please check your email inbox.</p>
        <p>
          Email: <b>{email}</b>
        </p>
        <p>
          If you have verified your email click the {'"'}Check{'"'} button
          below.
        </p>
        <div>
          <button onClick={checkClick} disabled={checking}>
            Check
          </button>
          <button
            className={emailResentStatus}
            onClick={resendEmailClick}
            disabled={['sending', 'sent'].includes(emailResentStatus)}
          >
            Re-send email
          </button>
          <button onClick={logoutClick}>Logout</button>
        </div>
      </Modal>
      {checking && <LoadingSquares />}
    </BlackOverlay>
  );
};

export default ConfirmEmail;
