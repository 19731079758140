import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import './Matchmaking.scss';

import socket from 'src/components/common/WebSocketConnection/websocket';

import { setPlayedGames } from 'src/store/app/actions';
import { setAppView } from 'src/store/app/actions';
import Timer from 'src/components/common/Timer';
import ReadyCheck from 'src/components/homeView/ReadyCheck';
import GameSquare from 'src/components/gamesCreator/GameSquare';
import { GAMES_LIST } from 'src/games/types';
import type { GameNameType } from 'src/games/types';

const getTopSectionWidth = (gamesAmount: number) => {
  const gameSquareSize = 144;
  const maxMultiplier = Math.min(
    gamesAmount,
    Math.floor(window.innerWidth / gameSquareSize),
  );
  return `${maxMultiplier * gameSquareSize}px`;
};

const Matchmaking = () => {
  const [games, setGames] = useState(
    GAMES_LIST.map((gameName) => ({
      name: gameName,
      enabled: true,
      playersWaiting: -1,
    })),
  );
  const [searching, setSearching] = useState(false);
  const [totalPlayersWaiting, setTotalPlayersWaiting] = useState(-1);
  const [readyCheck, setReadyCheck] = useState<string | null>(null);
  const [readyCheckGames, setReadyCheckGames] = useState([]);
  const [readyCheckDuration, setReadyCheckDuration] = useState(0);
  const [topSectionWidth, setTopSectionWidth] = useState(
    getTopSectionWidth(games.length),
  );

  const dispatch = useDispatch();

  const matchmakingSocketListener = (event: any) => {
    const action = JSON.parse(event.data);
    const { type, payload } = action;

    switch (type) {
      case 'SEND_MATCHMAKING_PLAYERS_WAITING': {
        const { perGame, total } = payload;
        setGames((prev) =>
          prev.map((game) => ({
            ...game,
            playersWaiting: perGame[game.name] || 0,
          })),
        );
        setTotalPlayersWaiting(total);
        break;
      }

      case 'MATCHMAKING_REQUEST_READY_CHECK': {
        const { readyCheckId, readyTimeoutSeconds, games } = payload;
        setReadyCheckDuration(readyTimeoutSeconds);
        setReadyCheck(readyCheckId);
        setReadyCheckGames(games);
        setSearching(false);
        break;
      }

      case 'MATCHMAKING_CANCEL_READY_CHECK': {
        const { accepted } = payload;
        if (accepted) setSearching(true);
        setReadyCheck(null);
        break;
      }

      case 'GAME_STARTING': {
        const { battleId, games } = payload;
        console.log('Matchmaking game starting...');
        dispatch(setPlayedGames(battleId, games));
        dispatch(setAppView('game'));
        break;
      }

      default:
        break;
    }
  };

  useEffect(() => {
    socket.addEvent({
      name: 'Matchmaking',
      type: 'message',
      handler: matchmakingSocketListener,
    });

    socket.sendJSON({
      type: 'GET_MATCHMAKING_PLAYERS_COUNT',
    });

    return () => {
      socket.instance.send(
        JSON.stringify({
          type: 'LEAVE_MATCHMAKING',
        }),
      );

      socket.removeEvent({
        name: 'Matchmaking',
        type: 'message',
        handler: matchmakingSocketListener,
      });
    };
  }, []);

  const startSearch = () => {
    socket.sendJSON({
      type: 'JOIN_MATCHMAKING',
      payload: {
        games: games.filter((game) => game.enabled).map((game) => game.name),
      },
    });

    setSearching(true);
  };

  const cancelSearch = () => {
    socket.instance.send(
      JSON.stringify({
        type: 'LEAVE_MATCHMAKING',
      }),
    );

    setSearching(false);
  };

  const toggleGame = (gameName: GameNameType) => {
    // Prevent toggling if search in progress
    if (searching) return;

    setGames((prev) => {
      const foundGameIndex = prev.findIndex((game) => game.name === gameName);
      if (foundGameIndex < 0) return prev;
      return [
        ...prev.slice(0, foundGameIndex),
        {
          ...prev[foundGameIndex],
          enabled: !prev[foundGameIndex].enabled,
        },
        ...prev.slice(foundGameIndex + 1),
      ];
    });
  };

  useEffect(() => {
    // TODO: Consider improvement in the future.
    // I've tried a pure-css way, but it was not working
    const onWindowResize = () => {
      setTopSectionWidth(getTopSectionWidth(games.length));
    };

    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [games]);

  return (
    <div className="Matchmaking">
      {readyCheck && (
        <ReadyCheck
          checkId={readyCheck}
          duration={readyCheckDuration}
          games={readyCheckGames}
        />
      )}

      <div className="top" style={{ width: topSectionWidth }}>
        {searching ? (
          <>
            <div className="search-info">
              <div>Total players waiting: {totalPlayersWaiting}</div>
              <div>
                Waiting for: <Timer />
              </div>
            </div>
            <button className="search-button" onClick={cancelSearch}>
              Cancel
            </button>
          </>
        ) : (
          <>
            <div className="search-info">
              <div>Total players waiting: {totalPlayersWaiting}</div>
            </div>
            <button className="search-button" onClick={startSearch}>
              Search...
            </button>
          </>
        )}
      </div>

      <div className="games-list">
        {games.map((game) => (
          <div
            key={game.name}
            className={['game', game.enabled ? 'added' : ''].join(' ')}
          >
            <div
              className="players-waiting"
              title={`Players waiting for ${game.name}`}
            >
              {game.playersWaiting}
            </div>
            <GameSquare
              type="check-list"
              size="large"
              game={{ name: game.name, settings: {} }}
              toggleGame={() => toggleGame(game.name)}
              gameEnabled={game.enabled}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Matchmaking;
