import React, { useState, useEffect } from 'react';

import socket, {
  WS_READY_STATES
} from 'src/components/common/WebSocketConnection/websocket';

import './VersionsInfo.scss';

const VersionsInfo = () => {
  const [serverVersion, setServerVersion] = useState('');

  const versionsInfoSocketListener = (event: any) => {
    const action = JSON.parse(event.data);
    const { type, payload } = action;

    switch (type) {
      case 'SEND_SERVER_VERSION': {
        const { version } = payload;
        setServerVersion(version);
        break;
      }

      default:
        break;
    }
  };

  const getServerVersion = async () => {
    await socket.waitForSocketStatus();
    if (socket.instance.readyState === WS_READY_STATES.OPEN) {
      socket.instance.send(JSON.stringify({
        type: 'GET_SERVER_VERSION',
      }));
    }
  };

  useEffect(() => {
    socket.addEvent({
      name: 'VersionsInfo',
      type: 'message',
      handler: versionsInfoSocketListener
    });

    getServerVersion();

    return () => {
      socket.removeEvent({
        name: 'VersionsInfo',
        type: 'message',
        handler: versionsInfoSocketListener
      });
    };
  }, []);

  return (
    <div className="VersionsInfo">
      <div><b>Versions</b></div>
      <div>Server: {serverVersion}</div>
      <div>Client: {process.env.REACT_APP_VERSION}</div>
    </div>
  );
};

export default VersionsInfo;
