import React from 'react';

import styles from './Modal.module.scss';

interface ModalPropsInterface {
  children: React.ReactNode;
  showCloseButton?: boolean;
  onCloseClick?: () => void;
}

const Modal = ({
  children,
  showCloseButton = true,
  onCloseClick,
}: ModalPropsInterface) => {
  return (
    <div className={styles.Modal}>
      {showCloseButton && (
        <div className={styles.closeHolder}>
          <button
            className={styles.closeButton}
            onClick={() => onCloseClick?.()}
          >
            X
          </button>
        </div>
      )}
      {children}
    </div>
  );
};

export default Modal;
