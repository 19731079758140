export const SET_USER_NAME = 'SET_USER_NAME';

export interface SetUserActionInterface {
  type: 'SET_USER_NAME';
  payload: {
    username: string;
  }
}
export const setUserName = (username: string): SetUserActionInterface => ({
  type: SET_USER_NAME,
  payload: {
    username,
  }
});
