import React from 'react';

import './OptionBox.scss';

interface BaseParamsType {
  name: string;
  placeholder?: string;
  value?: string;
  required?: boolean;
}

interface SelectBoxParamsType extends BaseParamsType {
  type: 'select';
  options: string[] | readonly string[];
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
}

interface InputBoxParamsType extends BaseParamsType {
  type: 'text' | 'number';
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

type OptionsBoxParamsType = SelectBoxParamsType | InputBoxParamsType;

const InputBox = ({
  name,
  placeholder,
  value,
  required,
  onChange,
  type,
  min,
  max,
  minLength,
  maxLength,
  pattern,
}: InputBoxParamsType) => {
  return (
    <>
      <div className="name">{name}</div>
      <input
        type={type}
        placeholder={placeholder}
        value={value || ''}
        onChange={onChange}
        min={min}
        max={max}
        minLength={minLength}
        maxLength={maxLength}
        required={required}
        pattern={pattern}
      />
    </>
  );
};

const SelectBox = ({
  name,
  value,
  required,
  onChange,
  options,
}: SelectBoxParamsType) => {
  return (
    <>
      <div className="name">{name}</div>
      <select value={value} onChange={onChange} required={required}>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </>
  );
};

const OptionBox = (props: OptionsBoxParamsType) => {
  return (
    <div className="OptionBox">
      {props.type === 'select' ? (
        <SelectBox {...props} />
      ) : (
        <InputBox {...props} />
      )}
    </div>
  );
};

export default OptionBox;
