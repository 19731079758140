import React, { useState } from 'react';
import type { FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { ReduxStateType } from 'src/store/reducers';
import { backendUrl, fetchServer } from 'src/utils/serverRequests';
import { showPopup } from 'src/utils/popups';
import styles from './Feedback.module.scss';
import BlackOverlay from '../BlackOverlay/BlackOverlay';
import LoadingSquares from '../LoadingSquares/LoadingSquares';
import SmallButton from '../SmallButton';

const Feedback = () => {
  const [opened, setOpened] = useState(false);
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const dispatch = useDispatch();

  const accountEmail = useSelector(
    (state: ReduxStateType) => state.user.username,
  );

  const sendFeedback = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSending(true);

    const data = await fetchServer({
      url: `${backendUrl}/sendFeedback`,
      body: {
        email,
        description,
      },
    });
    if (data.result === 'OK') {
      setIsSent(true);
    } else {
      showPopup(dispatch, {
        message: [
          'Failed to send feedback! Please try again.',
          `Reason: ${data.result}`,
        ].join(' '),
        color: 'red',
      });
    }
    setIsSending(false);
  };

  const closeAndReset = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setOpened(false);
    setDescription('');
    setIsSent(false);
  };

  return (
    <div className="Feedback">
      {opened && (
        <BlackOverlay>
          <div className={styles.feedbackModal}>
            {!isSent ? (
              <>
                <div className={styles.closeHolder}>
                  <button
                    className={styles.closeButton}
                    onClick={() => setOpened(false)}
                  >
                    X
                  </button>
                </div>
                <form onSubmit={sendFeedback}>
                  {!accountEmail && (
                    <div>
                      <p>Email</p>
                      <input
                        type="email"
                        required
                        value={email}
                        placeholder="Your email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  )}
                  <div>
                    <p>Description</p>
                    <textarea
                      required
                      value={description}
                      placeholder="Your feedback..."
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                  <button disabled={isSending}>Send</button>
                </form>
              </>
            ) : (
              <>
                <form onSubmit={closeAndReset}>
                  <div>Sent!</div>
                  <div>Thank you for your feedback.</div>
                  <button>OK</button>
                </form>
              </>
            )}
            {isSending && <LoadingSquares />}
          </div>
        </BlackOverlay>
      )}
      <SmallButton onClick={() => setOpened(true)}>Feedback</SmallButton>
    </div>
  );
};

export default Feedback;
